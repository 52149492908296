// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cta-block h1 {
  font-size: 2.2rem;
}
.cta-block h1 span {
  color: var(--color-white);
}`, "",{"version":3,"sources":["webpack://./src/components/general/cta_block/style.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAEI;EACE,yBAAA;AAAN","sourcesContent":[".cta-block{\n  h1{\n    font-size: 2.2rem;\n\n    span{\n      color: var(--color-white);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
