import React, { useEffect } from "react";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../services/axios";
import BigLoader from "../../../components/main/big_loader/BigLoader";
import { useTranslation } from "react-i18next";

let interval = null;

const GenerateQuiz = () => {
	const { t } = useTranslation("quizzes");
	const { id } = useParams();
	const navigate = useNavigate();

	const is_quiz_generated = async () => {
		const quiz = await axios
			.get(`/quizzes/${id}`)
			.then((r) => r.data)
			.catch((e) => null);
		if (quiz && quiz.created) {
			clearInterval(interval);
			navigate(`/quizzes/${quiz.id}/play`);
		}
	};

	useEffect(() => {
		document.getElementsByTagName("footer")[0].style.display = "none";
		document.getElementsByTagName("header")[0].style.display = "none";

		interval = setInterval(() => {
			is_quiz_generated();
		}, 1000);

		return () => {
			clearInterval(interval);
			document.getElementsByTagName("footer")[0].style.display = "flex";
			document.getElementsByTagName("header")[0].style.display = "flex";
		};
	}, []);

	return (
		<div id="generate-quiz-page">
			<section>
				<div className="container">
					<h1>{t("wait_while_quiz_is_generated")}</h1>
					<div className="loader-container">
						<BigLoader />
					</div>
				</div>
			</section>
		</div>
	);
};

export default GenerateQuiz;
