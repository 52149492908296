import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import "./style.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Google from "@mui/icons-material/Google";
import LoadingButton from "../../../components/main/loading_button/LoadingButton";
import { useGoogleLogin } from "@react-oauth/google";
import { App } from "antd";
import AuthPresentation from "../../../components/auth/auth_presentation/AuthPresentation";

const Login = () => {
	const { login, login_google } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const { notification } = App.useApp();
	let [searchParams, setSearchParams] = useSearchParams();

	const google_login = useGoogleLogin({
		flow: "auth-code",
		ux_mode: "redirect",
		redirect_uri: process.env.REACT_APP_REDIRECT_URI,
	});
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});
	const navigate = useNavigate();

	const { t, i18n } = useTranslation(["home", "main", "messages"]);

	const handleClick = async () => {
		setLoading(true);
		const log = await login(formData.email, formData.password);

		setLoading(false);
		if (log == true) {
			navigate("/profile");
		} else {
			if (log == "This email already exist and is connected with google") {
				notification.error({
					message: t("messages:error_login_message"),
					description: t("messages:error_login_google_description"),
					placement: "bottomLeft",
				});
			} else if (log == "Username or password is incorrect") {
				notification.error({
					message: t("messages:error_login_message"),
					description: t("messages:error_login_bad_password_description"),
					placement: "bottomLeft",
				});
			}
		}
	};

	const log_google = async () => {
		const code = searchParams.get("code");
		setLoading(true);
		const log = await login_google(code);
		setLoading(false);
		if (log) {
			navigate("/profile");
		}
	};

	// For mobile header color
	useEffect(() => {
		document.querySelector(".mobile-header").classList.add("on-light");
		if (searchParams.get("code")) {
			log_google();
		}
		return () => {
			document.querySelector(".mobile-header").classList.remove("on-light");
		};
	}, []);

	return (
		<section id="login">
			<section className={"container for-log g-section-padding mb-20"}>
				<div className={"form-container flex flex-col gap-8 w-full lg:w-1/2"}>
					<div className={"text-container flex flex-col gap-2"}>
						<h2
							className={"section-title"}
							dangerouslySetInnerHTML={{
								__html: t("login_title", { interpolation: { escapeValue: false } }),
							}}
						></h2>
						<p
							className={"opacity-80 text-sm"}
							dangerouslySetInnerHTML={{
								__html: t("login_desc", { interpolation: { escapeValue: false } }),
							}}
						></p>
					</div>
					<form className={""}>
						<div className={"label-line w-full mb-2"}>
							<label id={"email"}>{t("login_email_text")}</label>
						</div>
						<input
							placeholder={t("login_email_placeholder_text")}
							value={formData.email}
							onChange={(e) => setFormData({ ...formData, email: e.target.value })}
							type="email"
							autoComplete="email"
						/>

						<div
							className={
								"label-line flex flex-row justify-between items-center w-full mb-2"
							}
						>
							<label id={"email"}>{t("login_password_text")}</label>
							<Link
								to={"/forgot-password"}
								className={"text-xs self-end text-brow_color"}
							>
								{t("login_forgot_password")}
							</Link>
						</div>
						<input
							placeholder={t("login_password_placeholder_text")}
							value={formData.password}
							onChange={(e) => setFormData({ ...formData, password: e.target.value })}
							type="password"
							autoComplete="password"
							onKeyDown={(e) => {
								if (e.key == "Enter") {
									handleClick();
								}
							}}
						/>

						<LoadingButton loading={loading} onClick={handleClick}>
							{t("login_button_text")}
						</LoadingButton>
					</form>
					<Link
						to={"/register"}
						className={"text-text_color other-way"}
						dangerouslySetInnerHTML={{
							__html: t("login_other_log_text", {
								interpolation: { escapeValue: false },
							}),
						}}
					></Link>
					<div className={"w-full flex flex-col gap-4"}>
						<div className={"w-full relative"}>
							<p className={"simple-line"}>{t("or_sign_google")}</p>
						</div>
						<div className={"social-connexion flex flex-row gap-8"}>
							<span
								onClick={google_login}
								className={
									"text-brow_color border-brow_color p-2 border-2 rounded-full cursor-pointer"
								}
							>
								<Google />
							</span>
							{/* <a
								href={""}
								className={
									"text-brow_color border-brow_color p-2 border-2 rounded-full"
								}
							>
								<Facebook />
							</a>
							<a
								href={""}
								className={
									"text-brow_color border-brow_color p-2 border-2 rounded-full"
								}
							>
								<Github />
							</a> */}
						</div>
					</div>
				</div>
				<AuthPresentation />
			</section>
		</section>
	);
};

export default Login;
