import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../components/main/loading_button/LoadingButton";
import { App } from "antd";
import AuthPresentation from "../../../components/auth/auth_presentation/AuthPresentation";

const Register = () => {
	const { register } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
	});
	const { t } = useTranslation(["home", "main", "messages"]);
	const { notification } = App.useApp();
	const navigate = useNavigate();

	const submit_form = async (e) => {
		if (formData.password.length < 8) {
			e.preventDefault();
			notification.error({
				message: t("messages:error_register_password_message"),
				description: t("messages:error_register_password_description"),
				placement: "bottomLeft",
			});
		} else if (document.getElementById("register-form").checkValidity()) {
			e.preventDefault();
			setLoading(true);
			const regist = await register(
				formData.first_name,
				formData.last_name,
				formData.email,
				formData.password
			);
			if (regist == true) {
				navigate(`/verify-email?email=${formData.email}`);
			} else {
				notification.error({
					message: t("messages:error_register_email_message"),
					description: t("messages:error_register_email_description"),
					placement: "bottomLeft",
				});
			}
			setLoading(false);
		}
	};

	// For mobile header color
	useEffect(() => {
		document.querySelector(".mobile-header").classList.add("on-light");
		return () => {
			document.querySelector(".mobile-header").classList.remove("on-light");
		};
	}, []);

	return (
		<section id="register">
			<section className={"container for-log g-section-padding mb-20"}>
				<div className={"form-container flex flex-col gap-8 w-full lg:w-1/2"}>
					<div className={"text-container flex flex-col gap-2"}>
						<h2
							className={"section-title"}
							dangerouslySetInnerHTML={{
								__html: t("register_title", {
									interpolation: { escapeValue: false },
								}),
							}}
						></h2>
						<p
							className={"opacity-80 text-sm"}
							dangerouslySetInnerHTML={{
								__html: t("register_desc", {
									interpolation: { escapeValue: false },
								}),
							}}
						></p>
					</div>
					<form className={""} id="register-form" onSubmit={submit_form}>
						<div className={"label-line w-full mb-2"}>
							<label htmlFor="first_name_input" id={"name"}>
								{t("register_first_name_text")}
							</label>
						</div>
						<input
							placeholder={t("register_first_name_placeholder_text")}
							value={formData.first_name}
							id="first_name_input"
							onChange={(e) =>
								setFormData({ ...formData, first_name: e.target.value })
							}
							required
							type="text"
							autoComplete="given-name"
						/>
						<div className={"label-line w-full mb-2"}>
							<label htmlFor="last_name_input" id={"name"}>
								{t("register_last_name_text")}
							</label>
						</div>
						<input
							placeholder={t("register_last_name_placeholder_text")}
							value={formData.last_name}
							id="last_name_input"
							required
							onChange={(e) =>
								setFormData({ ...formData, last_name: e.target.value })
							}
							type="text"
							autoComplete="family-name"
						/>
						<div className={"label-line w-full mb-2"}>
							<label id={"email"}>{t("register_email_text")}</label>
						</div>
						<input
							placeholder={t("register_email_placeholder_text")}
							value={formData.email}
							onChange={(e) => setFormData({ ...formData, email: e.target.value })}
							type="email"
							required
							autoComplete="email"
						/>

						<div
							className={
								"label-line flex flex-row justify-between items-center w-full mb-2"
							}
						>
							<label id={"password"}>{t("register_password_text")}</label>
						</div>
						<input
							placeholder={t("register_password_placeholder_text")}
							value={formData.password}
							onChange={(e) => setFormData({ ...formData, password: e.target.value })}
							type="password"
							required
							autoComplete="password"
						/>
						<LoadingButton button={true} loading={loading} onClick={submit_form}>
							{t("register_button_text")}
						</LoadingButton>
					</form>
					<Link
						to={"/login"}
						className={"text-text_color other-way"}
						dangerouslySetInnerHTML={{
							__html: t("register_other_log_text", {
								interpolation: { escapeValue: false },
							}),
						}}
					></Link>
				</div>
				<AuthPresentation />
			</section>
		</section>
	);
};

export default Register;
