import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { Facebook, Linkedin, Instagram } from "react-feather";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const Footer = () => {
	const url = window.location.href;
	const { t } = useTranslation("main");

	const footerLinks = [
		{ title: "privacy", url: "/privacy" },
		{ title: "terms_conditions", url: "/terms" },
	];

	return (
		<footer id="footer" className="section g-section-padding bg-black text-white flex flex-col">
			<div className={"w-full flex flex-col justify-center items-center gap-4 lg:flex-row"}>
				<div className={"w-full lg:hidden flex justify-center items-center h-fit"}>
					<Link to="/">
						<span className="big-title">
							Quiz<span>Battle</span>
						</span>
					</Link>
				</div>
				<ul
					className={
						"text-link flex flex-row text-white w-full lg:w-1/3 justify-start gap-10 opacity-80 font-light flex-wrap h-fit"
					}
				>
					{footerLinks.map((item, index) => (
						<li key={index}>
							<Link to={item.url}>{t(item.title)}</Link>
						</li>
					))}
				</ul>
				<div
					className={
						"w-1/3 flex hidden lg:inline-block justify-center items-center h-fit"
					}
				>
					<Link className={"flex justify-center items-center"} to="/">
						<span className="big-title flex justify-center items-center self-center">
							Quiz<span>Battle</span>
						</span>
					</Link>
				</div>
				<div className="block-logo block w-full lg:w-1/3 mt-4 lg:mt-0 flex flex-row justify-between h-fit">
					<p className="global-text">{t("contacts")} :</p>
					<div className="social-networks flex items-center justify-between gap-4 w-2/3 h-2">
						<a href="https://www.facebook.com/sigmagency/" target="_blank">
							{" "}
							<Facebook color={"white"} />
						</a>

						<a href="https://www.instagram.com/sigmagency_be" target="_blank">
							{" "}
							<Linkedin color={"white"} />
						</a>

						<a href="https://be.linkedin.com/company/sigmagency" target="_blank">
							{" "}
							<Instagram color={"white"} />
						</a>
					</div>
				</div>
			</div>
			<div className="footer-copyright w-full opacity-50">
				<span>
					Made by{" "}
					<a target="_blank" href="https://sigmagency.be/">
						Sigmagency
					</a>
				</span>
			</div>
		</footer>
	);
};

export default Footer;
