import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { useTranslation } from "react-i18next";

const NotFound = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<div className="page-not-found">
			<section className="container mb-20 g-section-padding">
				<h1>404</h1>
				<h2>{t("not_found_h2")}</h2>
				<p>{t("not_found_description")}</p>
				<div className={`w-fit main-button `} onClick={() => navigate("/specifications")}>
					<span>{t("not_found_btn")}</span>
				</div>
			</section>
		</div>
	);
};

export default NotFound;
