// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-not-found {
  width: 100%;
  min-height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-not-found section {
  width: 100%;
}
.page-not-found h1 {
  margin-bottom: 20px;
  font-size: 4rem;
}
.page-not-found p {
  margin-top: 10px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/screens/not_found/style.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACD;AACC;EACC,WAAA;AACF;AAEC;EACC,mBAAA;EACA,eAAA;AAAF;AAGC;EACC,gBAAA;EACA,mBAAA;AADF","sourcesContent":[".page-not-found {\n\twidth: 100%;\n\tmin-height: 100svh;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\n\tsection {\n\t\twidth: 100%;\n\t}\n\n\th1 {\n\t\tmargin-bottom: 20px;\n\t\tfont-size: 4rem;\n\t}\n\n\tp {\n\t\tmargin-top: 10px;\n\t\tmargin-bottom: 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
