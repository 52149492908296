import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../general/section_title/section_title";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Grid } from "swiper/modules";

const PartnerBlock = () => {
	const { t, i18n } = useTranslation("home", "main");

	const logos = [
		{
			src: "https://sigmagency.be/wp-content/uploads/2023/03/icons8_phone-1.png",
			alt: "Entreprise 1",
		},
		{
			src: "https://sigmagency.be/wp-content/uploads/2023/03/icons8_new_post-1.png",
			alt: "Entreprise 2",
		},
		{
			src: "https://sigmagency.be/wp-content/uploads/2023/03/sigmagencyLogo.png",
			alt: "Entreprise 3",
		},
		{
			src: "https://sigmagency.be/wp-content/uploads/2023/03/icons8_location-1.png",
			alt: "Entreprise 4",
		},
		{ src: "https://sigmagency.be/wp-content/uploads/2023/03/Vector.png", alt: "Entreprise 5" },
	];

	return (
		<div className={"partner-block w-full flex flex-col pt-20 pb-20"}>
			<SectionTitle
				title={t("partner_title")}
				subtitle={t("partner_subtitle")}
				position={"middle"}
			/>
			<div className={"logo-slider w-full flex flex-row justify-between gap-8 mt-6"}>
				<Swiper
					watchSlidesProgress={true}
					modules={[Grid]}
					spaceBetween={200}
					slidesPerView={5}
					loop={true}
					breakpoints={{
						// Si la fenêtre est au moins 640px de large (taille typique pour le début de la taille tablette)
						640: {
							slidesPerView: 3,
						},
						// Si la fenêtre est au moins 1024px de large (taille typique pour les écrans d'ordinateur de taille moyenne)
						1024: {
							slidesPerView: 5,
						},
						// Si la fenêtre est au moins 1280px de large (taille typique pour les grands écrans)
						1280: {
							slidesPerView: 5,
						},
					}}
					autoplay={{
						delay: 1500, // 1000ms = 1 seconde
						disableOnInteraction: false, // Continue l'autoplay même après une interaction utilisateur
					}}
					grid
				>
					{logos.map((item, index) => (
						<SwiperSlide key={index}>
							<img key={index} src={item.src} alt={item.alt} className="logo" />
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};

export default PartnerBlock;
