// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.key-cta {
  border-radius: 20px;
}

.kpi-block {
  align-items: center;
  flex-wrap: initial;
  margin: 5dvh 0;
}`, "",{"version":3,"sources":["webpack://./src/components/home/kpi_block/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,kBAAA;EACA,cAAA;AACF","sourcesContent":[".key-cta{\n  border-radius: 20px;\n}\n\n.kpi-block{\n  align-items: center;\n  flex-wrap: initial;\n  margin: 5dvh 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
