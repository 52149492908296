import React, { useEffect } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import KPIBlock from "../../components/home/kpi_block/KPIBlock";
import CtaBlock from "../../components/general/cta_block/cta_block";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ImageSample from "../../assets/images/Process_img.png";
import FaqBlock from "../../components/features/faq_block/faq_block";

function CustomArgItem({ textArg }) {
	const { t } = useTranslation("home");

	return (
		<div className={"custom-arg-item flex flex-row w-full gap-2 items-center"}>
			<CheckCircle className={"check-circle"} />
			<p className={"text-black"}>{t(textArg)}</p>
		</div>
	);
}

function PossibilityItem({ text, description }) {
	const { t } = useTranslation("home");
	return (
		<div className={"possibility-item flex flex-col items-start gap-2 w-full h-max relative"}>
			<h3>{t(text)}</h3>
			<p>{t(description)}</p>
		</div>
	);
}

const Features = () => {
	const { t, i18n } = useTranslation("home", "main");
	useEffect(() => {
		document.getElementById("navbar").classList.add("reverse");

		return () => {
			document.getElementById("navbar").classList.remove("reverse");
		};
	}, []);

	const possibility = [
		{
			text: "feature_possibility1_title",
			description: "feature_possibility1_description",
		},
		{
			text: "feature_possibility2_title",
			description: "feature_possibility2_description",
		},
		{
			text: "feature_possibility3_title",
			description: "feature_possibility3_description",
		},
		{
			text: "feature_possibility4_title",
			description: "feature_possibility4_description",
		},
	];

	const customArg = [
		"features_key_point1",
		"features_key_point2",
		"features_key_point3",
		"features_key_point4",
	];

	return (
		<div id={"features"}>
			<section className="home-black-section">
				<div className="container">
					<h6 className={"subtitle section-title-item text-xs text-white"}>Features</h6>
					<h1
						className={"hero-title"}
						dangerouslySetInnerHTML={{
							__html: t("features_title", { interpolation: { escapeValue: false } }),
						}}
					></h1>
					<p>{t("features_subtitle")}</p>
					<a href={"#custom"} className={"main-button"} size="large" variant="outlined">
						{t("features_hero_button_text")}
					</a>
				</div>
			</section>
			<section
				id={"custom"}
				className={"custom-feature flex flex-col g-section-padding pt-20 pb-36 gap-6"}
			>
				<div className={"contain  flex flex-col gap-6  w-full items-start"}>
					<div className={"section-title"}>
						<h6 className={"text-xs"}>{t("features_custom_subtitle")}</h6>
						<h2
							className={"w-full md:w-full lg:w-4/5"}
							dangerouslySetInnerHTML={{
								__html: t("features_custom_title", {
									interpolation: { escapeValue: false },
								}),
							}}
						></h2>
					</div>
					<div
						className={
							"content flex flex-col-reverse w-full md:flex-row gap-12 justify-between items-start lg:items-center"
						}
					>
						<div className={"flex flex-col w-full md:w-1/2 gap-6"}>
							<p className={""}>{t("features_custom_desc")}</p>
							<div className={"flex flex-col gap-4"}>
								{customArg.map((item, index) => (
									<CustomArgItem textArg={item} key={index} />
								))}
							</div>

							<button className={"black-button w-fit"}>
								{t("features_custom_button_text")}
							</button>
						</div>
						<div
							className={
								"img-brown w-full md:w-1/2 lg:w-1/3 xl:w-1/2 xl:h-1/2 mb-8 md:mb-0 md:sticky top-40 "
							}
						>
							<img alt={t("features_title")} src={ImageSample} />
						</div>
					</div>
				</div>
			</section>
			<section
				className={"bg-background_gray w-full g-section-padding flex flex-col pt-20 pb-36"}
			>
				<div className={"contain flex flex-col gap-12 items-start w-full"}>
					<div className={"section-title"}>
						<h6 className={"text-xs"}>{t("features_possibility_subtitle")}</h6>
						<h2
							className={"w-full md:w-full lg:w-4/5"}
							dangerouslySetInnerHTML={{
								__html: t("features_possibility_title", {
									interpolation: { escapeValue: false },
								}),
							}}
						></h2>
					</div>

					<div
						className={
							"content flex flex-col w-full md:flex-row gap-10 justify-between items-start"
						}
					>
						<div
							className={
								"img-brown w-full md:w-1/2 lg:w-1/3  xl:w-1/2 xl:h-1/2 mb-8 md:mb-0 md:sticky top-40"
							}
						>
							<img alt={t("features_title")} src={ImageSample} />
						</div>
						<div className={"flex flex-col w-full md:w-1/2 lg:w-2/3 xl:w-1/2 gap-6"}>
							<div className={"flex flex-col w-full gap-8"}>
								{possibility.map((item, index) => (
									<PossibilityItem
										key={index}
										text={item.text}
										description={item.description}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className={"g-section-padding bg-black text-white"}>
				<KPIBlock blackBG={true}></KPIBlock>
			</section>
			<section className={"g-section-padding pb-20 pt-20"}>
				<FaqBlock />
			</section>
			<CtaBlock />
		</div>
	);
};

export default Features;
