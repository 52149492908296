import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import axios from "../../../services/axios";
import Loading from "../../../components/main/loading/Loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as RankingSvg } from "../../../assets/images/ranking.svg";
import { generate_profile_image } from "../../../helpers/image";
import { ChevronLeft } from "react-feather";
import { RWebShare } from "react-web-share";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../contexts/AuthContext";
import { format_time } from "../../../helpers/format";

function CompetitorItem({ rank, avatar, name, time, score, isYou = false }) {
	const { t } = useTranslation("quizzes");
	const minutes = String(Math.floor(time / 60)).padStart(2, "0");
	const seconds = String(parseInt(time) % 60).padStart(2, "0");

	return (
		<div
			className={`competitor-item flex w-full flex-col md:flex-row items-start md:items-center gap-4 justify-between ${
				isYou ? "isyou" : ""
			}`}
		>
			<div
				className={`left-side flex flex-row items-center justify-start gap-2 md:gap-4 lg:gap-8`}
			>
				<span className={"text-xl"}> # {rank}</span>
				<img
					src={`https://avatarapi.timour.me/avatar/classic/svg?s=${avatar}&u=5f0718e7-b406-4fa9-86e0-160173fc4d7e`}
				/>
				<p>{name}</p>
				{isYou ? <span className={"white-button text-xs"}> {t("you")} </span> : <></>}
			</div>
			<div className={"right-side flex flew-row items-center justify-end gap-6"}>
				<p className={""}>
					<span>{t("time")} :</span>
					<span className={"font-bold "}>{` ${minutes}:${seconds}`}</span>
				</p>
				<p className={isYou ? "white-button" : "black-button"}>
					{t("score")} :<span className={"font-bold "}> {score}</span>
				</p>
			</div>
		</div>
	);
}

const Ranking = () => {
	const { id } = useParams();
	const [currentQuiz, setCurrentQuiz] = useState(null);
	const { t, i18n } = useTranslation("quizzes");
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);

	// For sharing
	const currentUrl = window.location.href;
	const shareUrl = currentUrl.replace("ranking", "play");

	const load_current_quiz = async () => {
		const quiz = await axios
			.get(`/quizzes/${id}`)
			.then((r) => r.data)
			.catch((e) => null);
		if (quiz) {
			setCurrentQuiz(quiz);
		}
	};

	useEffect(() => {
		document.getElementsByTagName("header")[0].style.display = "none";
		load_current_quiz();

		return () => {
			document.getElementsByTagName("header")[0].style.display = "flex";
		};
	}, []);

	useEffect(() => {
		if (currentQuiz) {
			setTimeout(() => {
				document.querySelector(".toprank-wrapper")?.classList.add("shown");
			}, 100);
		}
	}, [currentQuiz]);

	if (!currentQuiz) {
		return <Loading />;
	}

	const ranking = currentQuiz.participations.sort((a, b) => {
		if (a.score == b.score) {
			return a.time - b.time;
		}
		return b.score - a.score;
	});

	const participation_ordered = [...ranking].slice(0, 3);
	if (participation_ordered.length <= 2) {
		participation_ordered.push({ empty: true });
		if (participation_ordered.length == 1) {
			participation_ordered.push({ empty: true });
		}
	}
	const last_participation = participation_ordered.pop();
	participation_ordered.unshift(last_participation);

	return (
		<div id={"quiz-ranking"}>
			<section className="home-black-section" style={{ paddingTop: "4vw" }}>
				<div
					className={
						"g-section-padding w-full flex self-center items-center justify-center"
					}
				>
					<div className="flex w-full justify-between flex-col md:flex-row gap-2 items-center">
						<button
							className={
								"rounded-full self-start md:self-center p-2 md:p-3 opacity-60"
							}
							onClick={() => navigate(-1)}
							style={{
								border: "1px solid rgba(255, 255, 255, 0.60)",
								background: "rgba(255, 255, 255, 0.50)",
								backdropFilter: "blur(2px)",
							}}
						>
							<ChevronLeft color={"white"} size={25} />
						</button>
						<div className={"flex flex-col items-center justify-center"}>
							<div>
								<Link to="/">
									<span className="big-title">
										Quiz<span>Battle</span>
									</span>
								</Link>
							</div>
							<h1 className={"mb-6"}>
								<span>{t("ranking")}</span>: {currentQuiz.title}
							</h1>
						</div>

						<RWebShare
							data={{
								text: "TestMe - quiz sharing",
								url: shareUrl,
								title: "Share your quiz",
							}}
							onClick={() => console.log("shared successfully!")}
						>
							<button className={"white-button"}>
								{t("home:ranking_share_btn")}
							</button>
						</RWebShare>
					</div>
				</div>
			</section>
			<section className={"g-section-padding z-10 "}>
				<div className="toprank-container shapedBackgroundYellow">
					<div className="toprank-wrapper">
						{participation_ordered.map((participant, index) =>
							participant.empty ? null : (
								<div
									key={index}
									className={`rank-item ${["third", "first", "second"][index]} `}
								>
									<div className="user">
										<div className="top-picture">
											<img src={generate_profile_image(participant)} />
											<div className={"position"}># {[3, 1, 2][index]}</div>
										</div>

										<div className="user-data">
											<div className={"player-name"}>
												{participant.full_name}
											</div>
											<div className={"player-score"}>
												{t("score")}:<span> {participant.score} </span>
											</div>
											<div className={"player-score"}>
												{t("time")}:
												<span> {format_time(participant.time)} </span>
											</div>
											{participant.user_id === user.id && (
												<div className="you">{t("you")}</div>
											)}
										</div>
									</div>
									<RankingSvg />
								</div>
							)
						)}
					</div>
				</div>
			</section>
			<section className={"g-section-padding mt-10 mb-20"}>
				{ranking.length > 3 ? (
					<div className={"competitor-container w-full"}>
						{ranking.slice(3).map((elem, index) => (
							<CompetitorItem
								key={index}
								rank={index + 4}
								avatar={elem.avatar}
								name={elem.full_name}
								time={elem.time}
								score={elem.score}
								isYou={elem.user_id === user.id}
							/>
						))}
					</div>
				) : (
					""
				)}
			</section>
		</div>
	);
};

export default Ranking;
