// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#generate-quiz-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
#generate-quiz-page .loader-container {
  display: flex;
  position: relative;
  width: 200px;
  height: 200px;
  margin: 40px 0;
}
#generate-quiz-page .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/screens/quizzes/generate_quiz/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,aAAA;AACD;AACC;EACC,aAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EAEA,cAAA;AAAF;AAGC;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;AADF","sourcesContent":["#generate-quiz-page {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\twidth: 100%;\n\theight: 100vh;\n\n\t.loader-container {\n\t\tdisplay: flex;\n\t\tposition: relative;\n\t\twidth: 200px;\n\t\theight: 200px;\n\n\t\tmargin: 40px 0;\n\t}\n\n\t.container {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tmax-width: 600px;\n\t\ttext-align: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
