// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1500px) {
  #profile .container {
    padding: 0 !important;
  }
}
#profile .home-black-section {
  display: flex;
  overflow-x: hidden;
}
#profile .home-black-section .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  padding: 0 50px;
}
#profile .home-black-section .container .content {
  width: 50%;
}
@media (max-width: 768px) {
  #profile .home-black-section .container .content {
    width: 100%;
  }
}
#profile .home-black-section .container p,
#profile .home-black-section .container h1 {
  text-align: start;
}
#profile .personal-info {
  background: #fff;
  box-shadow: 0px 4px 60px 3px rgba(0, 0, 0, 0.15);
  padding: 6vh 5vh;
  border-radius: 20px;
  transform: translateY(-80px);
}
#profile .personal-info form {
  display: flex;
  flex-direction: column;
}
#profile .subscrition-info {
  padding-top: 30px;
}
@media (max-width: 1050px) {
  #profile .personal-info {
    transform: translateY(0);
  }
}
#profile input:disabled {
  border: 0;
  background: rgba(0, 0, 0, 0.01);
  transition: ease-out all 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/screens/profile/style.scss"],"names":[],"mappings":"AACC;EACC;IACC,qBAAA;EAAD;AACF;AAGC;EACC,aAAA;EACA,kBAAA;AADF;AAEE;EACC,WAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,iBAAA;EACA,eAAA;AAAH;AAEG;EACC,UAAA;AAAJ;AAGG;EACC;IACC,WAAA;EADH;AACF;AAIG;;EAEC,iBAAA;AAFJ;AAOC;EACC,gBAAA;EACA,gDAAA;EACA,gBAAA;EACA,mBAAA;EACA,4BAAA;AALF;AAOE;EACC,aAAA;EACA,sBAAA;AALH;AASC;EACC,iBAAA;AAPF;AAUC;EACC;IACC,wBAAA;EARD;AACF;AAWC;EACC,SAAA;EACA,+BAAA;EACA,6BAAA;AATF","sourcesContent":["#profile {\n\t@media (max-width: 1500px) {\n\t\t.container {\n\t\t\tpadding: 0 !important;\n\t\t}\n\t}\n\n\t.home-black-section {\n\t\tdisplay: flex;\n\t\toverflow-x: hidden;\n\t\t.container {\n\t\t\twidth: 100%;\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\talign-items: flex-start;\n\t\t\ttext-align: start;\n\t\t\tpadding: 0 50px;\n\n\t\t\t.content {\n\t\t\t\twidth: 50%;\n\t\t\t}\n\n\t\t\t@media (max-width: 768px) {\n\t\t\t\t.content {\n\t\t\t\t\twidth: 100%;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tp,\n\t\t\th1 {\n\t\t\t\ttext-align: start;\n\t\t\t}\n\t\t}\n\t}\n\n\t.personal-info {\n\t\tbackground: #fff;\n\t\tbox-shadow: 0px 4px 60px 3px rgba(0, 0, 0, 0.15);\n\t\tpadding: 6vh 5vh;\n\t\tborder-radius: 20px;\n\t\ttransform: translateY(-80px);\n\n\t\tform {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t}\n\t}\n\n\t.subscrition-info {\n\t\tpadding-top: 30px;\n\t}\n\n\t@media (max-width: 1050px) {\n\t\t.personal-info {\n\t\t\ttransform: translateY(0);\n\t\t}\n\t}\n\n\tinput:disabled {\n\t\tborder: 0;\n\t\tbackground: rgba(0, 0, 0, 0.01);\n\t\ttransition: ease-out all 0.3s;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
