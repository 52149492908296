import { CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const LoadingButton = ({
	onClick,
	children,
	loading,
	className,
	disabled = false,
	button = false,
}) => {
	const buttonRef = useRef(null);
	const [buttonWidth, setButtonWidth] = useState(0);

	useEffect(() => {
		if (!loading) {
			if (buttonRef.current) {
				const width = buttonRef.current.offsetWidth;
				setButtonWidth(width);
			}
		}
	}, [loading]);

	if (button) {
		return (
			<button
				type="submit"
				ref={buttonRef}
				style={{ minWidth: `${buttonWidth}px` }}
				className={`w-fit main-button ${className} ${
					loading || disabled ? "disabled" : ""
				}`}
				onClick={loading || disabled ? null : onClick}
			>
				{loading ? <CircularProgress size={16} /> : children}
			</button>
		);
	}

	return (
		<div
			ref={buttonRef}
			style={{ minWidth: `${buttonWidth}px` }}
			className={`w-fit main-button ${className} ${loading || disabled ? "disabled" : ""}`}
			onClick={loading || disabled ? null : onClick}
		>
			{loading ? <CircularProgress size={16} /> : children}
		</div>
	);
};

export default LoadingButton;
