import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import AuthContext from "../../../contexts/AuthContext";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "../../../services/axios";
import Loading from "../../../components/main/loading/Loading";
import { Button, Snackbar } from "@mui/material";
import { ChevronLeft } from "react-feather";
import ProgressBar from "../../../components/quizzes/progress_bar/progress_bar";
import AnswerItem from "../../../components/quizzes/answer_item/answer_item";
import { useTranslation } from "react-i18next";
import { App } from "antd";

const Timer = ({ start }) => {
	const { t } = useTranslation("quizzes");
	const [time, setTime] = useState(parseInt((Date.now() - new Date(start).getTime()) / 1000));

	// Convertit le temps en minutes et secondes
	const minutes = String(Math.floor(time / 60)).padStart(2, "0");
	const seconds = String(time % 60).padStart(2, "0");

	useEffect(() => {
		// Démarre le timer: appelle `setTime` chaque seconde
		const timerInterval = setInterval(() => {
			setTime((prevTime) => prevTime + 1);
		}, 1000);

		// Cleanup: arrête le timer lorsque le composant est retiré du DOM
		return () => {
			clearInterval(timerInterval);
		};
	}, []);

	return (
		<p className={"data-item"}>
			{t("time")}: <span>{`${minutes}:${seconds}`}</span>
		</p>
	);
};

const PlayQuiz = () => {
	const [currentQuiz, setCurrentQuiz] = useState(null);
	const { user } = useContext(AuthContext);
	const { id } = useParams();
	const { t } = useTranslation(["quizzes", "messages"]);
	const [loading, setLoading] = useState(-1);
	const [isCorrect, setIsCorrect] = useState(null);
	const [quizError, setquizError] = useState(false);
	const navigate = useNavigate();
	const { notification } = App.useApp();

	const load_current_quiz = async () => {
		const quiz = await axios
			.get(`/users/${user.id}/quizzes/${id}`)
			.then((r) => r.data)
			.catch((e) => null);
		const current_part = quiz?.quiz?.participations?.find((e) => e.user_id == user.id);
		if (current_part && current_part?.time) {
			navigate("/");
			notification.error({
				message: t("messages:error_quiz_launch_message"),
				description: t("messages:error_quiz_already_message"),
				placement: "bottomLeft",
			});
		} else if (quiz && !quiz.ended) {
			setCurrentQuiz({
				...quiz.quiz,
				started_at: quiz.user_quiz.started_at,
				current_question:
					quiz.user_quiz.current_question == quiz.quiz.questions.length
						? quiz.user_quiz.current_question - 1
						: quiz.user_quiz.current_question,
				ended: quiz.user_quiz.ended,
				answers: quiz.user_quiz.answers,
			});
		} else {
			navigate("/");
			notification.error({
				message: t("messages:error_quiz_launch_message"),
				description: t("messages:error_quiz_cannot_message"),
				placement: "bottomLeft",
			});
		}
	};

	const load_new_questions = async () => {
		const quiz = await axios
			.get(`/quizzes/${id}`)
			.then((r) => r.data)
			.catch((e) => null);
		if (quiz) {
			if (quiz.error) {
				setquizError(true);
			}
			setCurrentQuiz({
				...currentQuiz,
				questions: quiz.questions,
				completed: quiz.completed,
			});
		} else {
			navigate("/");
		}
	};

	const answer = async (answer_id) => {
		if (loading != -1) return;
		setLoading(answer_id);
		if (currentQuiz.questions.length != currentQuiz.question_count) {
			await load_new_questions();
		}
		const ret = await axios
			.put(`/quizzes/${currentQuiz.id}`, {
				question: currentQuiz.current_question,
				answer: answer_id,
			})
			.then((r) => r.data)
			.catch((e) => null);

		if (ret === false || ret) setIsCorrect(ret);
		setLoading(-1);
	};

	useEffect(() => {
		document.getElementsByTagName("footer")[0].style.display = "none";
		document.getElementsByTagName("header")[0].style.display = "none";
		load_current_quiz();
		return () => {
			document.getElementsByTagName("footer")[0].style.display = "flex";
			document.getElementsByTagName("header")[0].style.display = "flex";
		};
	}, []);

	const QuizAnswers = ({ current_question }) => {
		if (isCorrect !== null) {
			return (
				<div className="quiz-reveal">
					<span>
						<h4 className={isCorrect ? "correct" : "incorrect"}>
							{isCorrect ? t("congratulation_good_answer") : t("sorry_bad_answer")}
						</h4>
						{t("the_answer_is")}{" "}
						<span style={{ color: "#7D763E", fontWeight: "700" }}>
							{" "}
							{current_question.answers[current_question.correct_answer]?.answer}
						</span>
					</span>
					<p>{current_question.short_explanation}</p>
					<button
						className={"black-button"}
						onClick={() => {
							if (
								!currentQuiz.completed &&
								currentQuiz.current_question + 1 == currentQuiz.questions.length
							) {
								load_new_questions();
								return;
							}
							setIsCorrect(null);
							setCurrentQuiz({
								...currentQuiz,
								current_question: currentQuiz.current_question + 1,
							});
						}}
						variant="outlined"
					>
						{t("continue")}
					</button>
				</div>
			);
		}
		return (
			<div className="quiz-answers mb-10 md:w-full lg:w-10/12 xl:w-1/2">
				{current_question.answers.map((elem, index) => (
					<div
						style={{ cursor: "pointer" }}
						onClick={() => answer(elem.id)}
						key={index}
						className={`answer-item-container ${loading != -1 ? "loading" : ""} ${
							loading == index ? "selected" : "unselected"
						}`}
					>
						<AnswerItem
							number={index}
							key={index}
							text={elem.answer}
							full_width={true}
						/>
					</div>
				))}
			</div>
		);
	};

	if (!currentQuiz) {
		return <Loading />;
	}

	const current_question = currentQuiz.questions[currentQuiz.current_question];

	if (!current_question && isCorrect == null) {
		return <Navigate to={`/quizzes/${id}`} />;
	}

	const action = (
		<React.Fragment>
			<Button
				color="primary"
				size="small"
				onClick={() => {
					setquizError(false);
					navigate("/");
				}}
			>
				FERMER LE QUIZ
			</Button>
		</React.Fragment>
	);

	return (
		<div id="play-quiz">
			<Snackbar
				open={quizError}
				autoHideDuration={6000}
				onClose={() => setquizError(false)}
				message="Toutes nos excuses, une erreur a été détectée lors de la génération des questions"
				action={action}
			/>
			<section className="quiz-header">
				<div className="w-full">
					<div
						className={
							"g-section-padding w-full flex self-center items-center justify-center"
						}
					>
						<div className="flex w-full justify-between flex-col md:flex-row gap-2 items-center">
							<button
								className={
									"rounded-full self-start md:self-center p-2 md:p-3 opacity-60"
								}
								onClick={() => navigate("/")}
								style={{
									border: "1px solid rgba(0, 0, 0, 0.10)",
									background: "rgba(0, 0, 0, 0.10)",
									backdropFilter: "blur(2px)",
								}}
							>
								<ChevronLeft color={"black"} size={25} />
							</button>
							<div className={"flex flex-col items-center text-center"}>
								<h1 className={"mb-1"}>{currentQuiz.title}</h1>
								<p className={"opacity-70"}>Quiz#{currentQuiz.id}</p>
							</div>
							<div className="pagination hidden md:inline-block"></div>
						</div>
					</div>
				</div>
			</section>
			<section className="quiz-container g-section-padding">
				<div className="questions-container hero-title-black px-6 py-4 md:px-16 md:py-8">
					<div className={"quiz-data flex flex-col w-full gap-2"}>
						<div className="pagination self-end">
							<span className={"text-primary_yellow"}>
								{currentQuiz.current_question + 1}
							</span>
							{" / "}
							<span>{currentQuiz.question_count}</span>
						</div>
						<ProgressBar
							currentQuestion={currentQuiz.current_question + 1}
							maxQuestion={currentQuiz.question_count}
						/>
						<div className={"flex flex-row justify-between w-full flex-wrap"}>
							<div className={"flex md:gap-8 flex-wrap"}>
								<p className={"data-item"}>
									{t("level")}:{" "}
									<span>
										{t(
											[
												"easy",
												"normal",
												"intermediate",
												"difficult",
												"expert",
											][currentQuiz.difficulty]
										)}
									</span>
								</p>
								{/* <p className={"data-item"}>
									{" "}
									Moment: <span> After each question</span>{" "}
								</p> */}
							</div>
							<div className={"flex gap-8 flex-wrap"}>
								<Timer start={currentQuiz.started_at} />
								{/* <p className={"data-item"}>
									{" "}
									Score: <span>00</span>
								</p> */}
							</div>
						</div>
					</div>
					<div className={"question-data flex flex-col w-full text-center mt-4"}>
						<p>
							{t("question")} {currentQuiz.current_question + 1}
						</p>
						<h3 className={"text-center"}>{current_question.question}</h3>
						<p className={""}>{t("only_one_proposal")}</p>
					</div>
					<QuizAnswers current_question={current_question} />
				</div>
			</section>
			<section className={"bg-black w-full h-20 quiz-bottom"}></section>
		</div>
	);
};

export default PlayQuiz;
