import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import "../login/style.scss";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../components/main/loading_button/LoadingButton";
import { useGoogleLogin } from "@react-oauth/google";
import { App } from "antd";
import AuthPresentation from "../../../components/auth/auth_presentation/AuthPresentation";

const VerifyEmail = ({ verified = false }) => {
	const { verify_email } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const { notification } = App.useApp();
	let [searchParams, setSearchParams] = useSearchParams();

	const [formData, setFormData] = useState({
		code: "",
	});
	const navigate = useNavigate();

	const { t } = useTranslation(["home", "main", "messages"]);

	const handleClick = async () => {
		setLoading(true);
		const log = await verify_email(searchParams.get("email"), formData.code);

		setLoading(false);
		if (log == true) {
			navigate("/email-verified");
		} else {
			if (log == "bad code") {
				notification.error({
					message: t("messages:error_bad_code_message"),
					placement: "bottomLeft",
				});
			}
		}
	};
	// For mobile header color
	useEffect(() => {
		document.querySelector(".mobile-header").classList.add("on-light");
		return () => {
			document.querySelector(".mobile-header").classList.remove("on-light");
		};
	}, []);

	if (!verified && !searchParams.get("email")) {
		return <Navigate to={"/register"} />;
	}

	if (verified) {
		return (
			<section id="verify-email">
				<section className={"container for-log g-section-padding mb-20"}>
					<div className={"form-container flex flex-col gap-8 w-full lg:w-1/2"}>
						<div className={"text-container flex flex-col gap-2"}>
							<h2 className={"section-title"}>{t("email_verified_successfully")}</h2>
							<p className={"opacity-80 text-sm"}>{t("you_can_now_login")}</p>
						</div>
						<form className={""}>
							<LoadingButton loading={loading} onClick={() => navigate("/login")}>
								{t("login_button_text")}
							</LoadingButton>
						</form>
					</div>
					<AuthPresentation />
				</section>
			</section>
		);
	}

	return (
		<section id="verify-email">
			<section className={"container for-log g-section-padding mb-20"}>
				<div className={"form-container flex flex-col gap-8 w-full lg:w-1/2"}>
					<div className={"text-container flex flex-col gap-2"}>
						<h2
							className={"section-title"}
							dangerouslySetInnerHTML={{
								__html: t("verify_email", {
									interpolation: { escapeValue: false },
								}),
							}}
						></h2>
						<p
							className={"opacity-80 text-sm"}
							dangerouslySetInnerHTML={{
								__html: t("verify_email_desc", {
									email: searchParams.get("email"),
									interpolation: { escapeValue: false },
								}),
							}}
						></p>
					</div>
					<form className={""}>
						<div className={"label-line w-full mb-2"}>
							<label id={"email"}>{t("verification_code_label")}</label>
						</div>
						<input
							placeholder={t("verification_code_placeholder")}
							value={formData.code}
							onChange={(e) => setFormData({ ...formData, code: e.target.value })}
							type="text"
						/>

						<LoadingButton loading={loading} onClick={handleClick}>
							{t("verify_my_email")}
						</LoadingButton>
					</form>
				</div>
				<AuthPresentation />
			</section>
		</section>
	);
};

export default VerifyEmail;
