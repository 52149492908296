import React from "react";
import "./style.scss";

function SectionTitle({ subtitle, title, description, position }) {
	let pos = "";
	if (position == "right") {
		pos = "end";
	} else if (position == "middle") {
		pos = "center";
	} else {
		pos = "left";
	}

	return (
		<div
			className={
				"section-title w-full flex flex-col justify-" +
				pos +
				" items-" +
				pos +
				" align-" +
				pos
			}
			style={{ textAlign: position === "middle" ? "center" : position, alignItems: pos }}
		>
			<h6 className={"subtitle section-title-item text-xs"}>{subtitle}</h6>
			<h2
				dangerouslySetInnerHTML={{ __html: title }}
				className={"text-title section-title-item"}
			></h2>
			<p className={"section-title-item sm:w-full md:w-full lg:w-2/3 "}>{description}</p>
		</div>
	);
}

export default SectionTitle;
