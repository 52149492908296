import React, { useEffect } from "react";
import { createContext, useState } from "react";
import axios, { set_instance_token, unset_instance_token } from "../services/axios";
import auth_axios from "../services/auth_axios";
import { getSpecificData, storeData } from "../functions/store_data";
import Loading from "../components/main/loading/Loading";

const AuthContext = createContext();

export const AuthProvider = (props) => {
	const [user, setUser] = useState({
		first_name: "Anonymous",
		last_name: "User",
		token: null,
		quizzes: [],
	});

	const profile = async (token) => {
		const res = await axios
			.get(`/users`)
			.then((e) => e.data)
			.catch((e) => null);
		if (res) {
			storeData("user", res);
			const quizzes = await axios
				.get(`/users/${res.id}/quizzes`)
				.then((e) => e.data)
				.catch((e) => null);
			setUser({ ...res, token: token, quizzes: quizzes });
		} else {
			logout();
		}
	};

	const register = async (first_name, last_name, email, password) => {
		let error = "";
		const res = await auth_axios
			.post(`/users/register`, {
				first_name: first_name,
				last_name: last_name,
				username: email,
				password: password,
			})
			.then((e) => e.data)
			.catch((e) => {
				error = e.response?.data?.message;
				return null;
			});
		if (res) {
			await login(email, password);
			return true;
		}
		return error;
	};

	const login = async (email, password) => {
		let error = "";
		const res = await auth_axios
			.post(`/users/authenticate`, { username: email, password: password })
			.then((e) => e.data)
			.catch((e) => {
				error = e.response?.data?.message;
				return null;
			});
		if (!res) {
			logout();
			return error;
		} else {
			storeData("token", res.token);
			set_instance_token(res.token);
			await profile(res.token);
			return true;
		}
	};

	const verify_email = async (email, code) => {
		let error = "";
		const res = await auth_axios
			.put(`/users/register`, {
				email: email,
				code: code,
			})
			.then((e) => e.data)
			.catch((e) => {
				error = e.response?.data?.message;
				return null;
			});
		if (res) {
			return true;
		}
		return error;
	};

	const login_google = async (code) => {
		const res = await auth_axios
			.post(`/users/google`, { code: code, redirect_uri: process.env.REACT_APP_REDIRECT_URI })
			.then((e) => e.data)
			.catch((e) => null);
		if (!res) {
			logout();
			return false;
		} else {
			storeData("token", res.token);
			set_instance_token(res.token);
			await profile(res.token);
			return true;
		}
	};

	const update_profile = async (profile_data) => {
		const usr = await axios
			.post(`/me`, { ...profile_data, email: user.username })
			.then((e) => e.data)
			.catch((e) => null);

		if (usr) {
			setUser({ ...user, first_name: usr.first_name, last_name: usr.last_name });
			return { ...profile_data };
		} else {
			return { first_name: user.first_name, last_name: user.last_name, error: true };
		}
	};

	const setup = async () => {
		const token = getSpecificData("token");
		if (token) {
			set_instance_token(token);
			const usr = getSpecificData("user");
			if (usr) {
				const completed_user = {
					id: usr.id,
					first_name: usr.first_name,
					last_name: usr.last_name,
					is_anonymous: usr.is_anonymous,
					avatar: usr.avatar,
					picture: usr.picture,
					current_plan: usr.current_plan,
				};
				setUser({ ...user, token: token, ...completed_user });
			}

			await profile(token);
		} else {
			localStorage.clear();
			const user = await auth_axios
				.post("/users/anonymous-register")
				.then((e) => e.data)
				.catch((e) => null);
			if (user) {
				storeData("token", user.token);
				set_instance_token(user.token);
				await profile(user.token);
			}
		}
	};

	useEffect(() => {
		setup();
	}, []);

	const logout = async () => {
		let lang = getSpecificData("i18nextLng");
		if (!lang) lang = "en";
		localStorage.clear();
		storeData("i18nextLng", lang);
		unset_instance_token();
		setUser({
			first_name: "Anonymous",
			last_name: "User",
			token: "",
			quizzes: [],
		});
		const user = await auth_axios
			.post("/users/anonymous-register")
			.then((e) => e.data)
			.catch((e) => null);
		if (user) {
			storeData("token", user.token);
			set_instance_token(user.token);
			await profile(user.token);
		}
	};

	const is_login = () => {
		return user.token !== null && user.token !== "" && !user.is_anonymous;
	};

	const is_anonymous_or_login = () => {
		return user.token !== null && user.token !== "";
	};

	if (user.token === null) {
		return <Loading full_screen={true} />;
	}

	return (
		<AuthContext.Provider
			value={{
				user,
				login,
				login_google,
				register,
				logout,
				is_login,
				update_profile,
				is_anonymous_or_login,
				verify_email,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
