import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../general/section_title/section_title";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Scrollbar, Grid } from "swiper/modules";
import { ArrowLeft, ArrowRight } from "react-feather";
import { get_data_list } from "../../../helpers/content_manager";

function FaqSwiperItem({ title, description }) {
	return (
		<div className={"faq-item flex flex-col gap-2"}>
			<h3 className={"faq-title"}>{title}</h3>
			<div className={"faq-desc"} dangerouslySetInnerHTML={{ __html: description }} />
		</div>
	);
}

const FaqBlock = () => {
	const { t, i18n } = useTranslation("home", "main");
	const [faqCat, setFaqCat] = useState(1);
	const [canGo, setCanGo] = useState({
		left: true,
		right: true,
	});
	const [faq, setFaq] = useState([]);
	const categories = useRef([]);
	//const swiper = useSwiper(null);

	const swiperRef = useRef(null);

	const params = {
		on: {
			init: (swiper) => {
				swiperRef.current = swiper;
			},
		},
	};

	const load_faq = async () => {
		const [faqs, categ] = await Promise.all([
			get_data_list("faq_item", i18n.language),
			get_data_list("faq_category", i18n.language),
		]);
		categories.current = categ;
		setFaq(faqs);
	};

	useEffect(() => {
		load_faq();
	}, [i18n.language]);

	const handleNextSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
			const faqSelected = faq.filter((e) => e.fields.category == faqCat);
			setCanGo({
				left: swiperRef.current.visibleSlidesIndexes.includes(0) ? false : true,
				right: swiperRef.current.visibleSlidesIndexes.includes(faqSelected.length - 1)
					? false
					: true,
			});
		}
	};
	const handlePrevSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
			const faqSelected = faq.filter((e) => e.fields.category == faqCat);
			setCanGo({
				left: swiperRef.current.visibleSlidesIndexes.includes(0) ? false : true,
				right: swiperRef.current.visibleSlidesIndexes.includes(faqSelected.length - 1)
					? false
					: true,
			});
		}
	};

	useEffect(() => {
		const faqSelected = faq.filter((e) => e.fields.category == faqCat);
		if (swiperRef.current) {
			setCanGo({
				left: false,
				right: swiperRef.current.visibleSlidesIndexes
					? swiperRef.current.visibleSlidesIndexes.includes(faqSelected.length - 1)
						? false
						: true
					: false,
			});
		} else {
			setCanGo({
				left: false,
				right: faqSelected.length > 3 ? true : false,
			});
		}
	}, [faqCat, faq]);

	return (
		<div className={"faq-block w-full flex flex-col items-start gap-8"}>
			<div
				className={
					"header w-full flex flex-col lg:flex-row justify-between items-start lg:items-end"
				}
			>
				<SectionTitle
					title={t("features_faq_title")}
					subtitle={t("features_faq_subtitle")}
					position={"left"}
				></SectionTitle>
				<div className={""}>
					<ul className={"switcher"}>
						{categories.current.map((category, index) => (
							<li
								key={index}
								onClick={() => setFaqCat(category.id)}
								className={
									"switch-option " + (faqCat !== category.id ? "" : "active")
								}
							>
								{category.title}
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className={"w-full relative"}>
				<Swiper
					{...params}
					watchSlidesProgress={true}
					modules={[Navigation, Pagination, Scrollbar, Grid]}
					spaceBetween={100}
					slidesPerView={1}
					breakpoints={{
						// Si la fenêtre est au moins 640px de large (taille typique pour le début de la taille tablette)
						640: {
							slidesPerView: 2,
						},
						// Si la fenêtre est au moins 1024px de large (taille typique pour les écrans d'ordinateur de taille moyenne)
						1024: {
							slidesPerView: 2,
						},
						// Si la fenêtre est au moins 1280px de large (taille typique pour les grands écrans)
						1280: {
							slidesPerView: 3,
						},
					}}
					onSwiper={(swiper) => (swiperRef.current = swiper)}
					grid
				>
					{faq
						.filter((e) => e.fields.category == faqCat)
						.map((item, index) => (
							<SwiperSlide key={index}>
								<FaqSwiperItem
									title={item.fields.title}
									description={item.fields.content}
								/>
							</SwiperSlide>
						))}
				</Swiper>
			</div>
			<div className={"flex flex-row w-full justify-between items-center"}>
				<p style={{ textTransform: "capitalize" }}>
					{categories.current.filter((e) => e.id == faqCat)[0]?.title} question
				</p>
				<div>
					<div className={"btn-slider flex flex-row gap-4"}>
						<div
							className={`slider-arrow p-3 border-2 cursor-pointer ${
								canGo.left ? "" : "disabled"
							}`}
							onClick={() => handlePrevSlide()}
						>
							<ArrowLeft size={18} color={"black"} />
						</div>
						<div
							className={`slider-arrow bg-black p-3 border-2 border-black cursor-pointer ${
								canGo.right ? "" : "disabled"
							}`}
							onClick={() => handleNextSlide()}
						>
							<ArrowRight size={18} color={"white"} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FaqBlock;
