export const storeData = (key, value) => {
	if (typeof value === "string") {
		localStorage.setItem(key, value);
	} else {
		const jsonValue = JSON.stringify(value);
		localStorage.setItem(key, jsonValue);
	}
};

export const getSpecificData = (key) => {
	const jsonValue = localStorage.getItem(key);
	if (jsonValue) {
		try {
			return JSON.parse(jsonValue);
		} catch (error) {
			return jsonValue;
		}
	} else {
		return null;
	}
};

export const getData = () => {
	let get_dict = {
		token: null,
	};
	for (var key in get_dict) {
		const jsonValue = localStorage.getItem(key);
		try {
			get_dict[key] = jsonValue !== null ? JSON.parse(jsonValue) : null;
		} catch {
			get_dict[key] = null;
		}
	}
	return get_dict;
};
