import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "../../../services/axios";
import LoadingButton from "../../main/loading_button/LoadingButton";
import { get_data_list } from "../../../helpers/content_manager";
import { App } from "antd";

const GenerateQuizBlock = () => {
	const { t, i18n } = useTranslation(["home", "messages"]);
	const [quiz, setQuiz] = useState({
		title: "",
		difficulty: 4,
	});
	const [loading, setLoading] = useState(false);
	const [generalQuestions, setGeneralQuestions] = useState([]);
	const { notification } = App.useApp();

	const middleIndex = Math.floor(generalQuestions.length / 2);

	const line1Items = generalQuestions.slice(0, middleIndex);
	const line2Items = generalQuestions.slice(middleIndex);

	const navigate = useNavigate();

	// Fonction pour mettre à jour l'input
	function fieldInput(text = "test") {
		setQuiz({ ...quiz, title: text });
	}

	const generate_quiz = async () => {
		setLoading(true);
		const generated = await axios
			.post(`/quizzes`, {
				number_of_questions: 5,
				topic: quiz.title,
				difficulty: quiz.difficulty,
			})
			.then((r) => r.data)
			.catch((e) => null);
		if (generated) {
			navigate(`/quizzes/generate/${generated.id}`);
		} else {
			notification.error({
				message: t("messages:error_custom_quiz_daily_limit_message"),
				description: t("messages:error_custom_quiz_daily_limit_description"),
				placement: "bottomLeft",
			});
		}
		setLoading(false);
	};

	useEffect(() => {
		(async function load_questions() {
			const questions = await get_data_list("general_question", i18n.language);
			setGeneralQuestions(questions);
		})();
	}, [i18n.language]);

	return (
		<>
			<div className="quiz-generation-block">
				<span className="shortest text-center">{t("home_mainbox_fast_text")}</span>
				<h2 className="big text-center">{t("home_mainbox_title")}</h2>
				<span className="short text-center">{t("home_mainbox_explaination")}</span>
				<input
					id={"questionTitle"}
					value={quiz.title}
					className="cust-input"
					onChange={(e) => setQuiz({ ...quiz, title: e.target.value })}
					placeholder={t("home_mainbox_title_input")}
					style={{ width: 100 + "%" }}
				/>
				<div className={"animated-text flex flex-col justify-start align-top mt-1 mb-8"}>
					<div className="line">
						{line1Items.concat(line1Items).map((item, index) => (
							<span
								onClick={() => fieldInput(item.fields.title)}
								className={"history-question-item cursor-pointer"}
								key={index}
							>
								{item.fields.title}
							</span>
						))}
					</div>
					<div className="line reverse">
						{line2Items.concat(line2Items).map((item, index) => (
							<span
								onClick={() => fieldInput(item.fields.title)}
								className={"history-question-item cursor-pointer"}
								key={index}
							>
								{item.fields.title}
							</span>
						))}
					</div>
				</div>
				<div className={"flex flex-col md:flex-row align-center justify-center"}>
					<Link
						className={"black-button"}
						to="/specifications"
						state={{ title: quiz.title }}
					>
						{t("home_add_some_info")}
					</Link>
					<LoadingButton
						disabled={quiz.title == ""}
						className={"main-button ml-0 md:ml-6 mt-4 md:mt-0"}
						onClick={generate_quiz}
						loading={loading}
					>
						{t("home_generate_quiz")}
					</LoadingButton>
				</div>
			</div>
		</>
	);
};

export default GenerateQuizBlock;
