// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advantage-block {
  color: var(--color-white) !important;
}
.advantage-block p {
  color: var(--color-white) !important;
  opacity: 80%;
}
.advantage-block h6 {
  color: var(--color-white) !important;
  opacity: 80%;
}
.advantage-block h3 span {
  color: var(--color-primary);
}
.advantage-block .advantage-item {
  overflow: hidden;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.08);
  transition: all 0.5s;
}
.advantage-block .advantage-item .advantage-img-container {
  display: flex;
  padding-bottom: 4dvh;
}
.advantage-block .advantage-item .horizontal {
  box-shadow: -40px 44px 2px 0px rgba(255, 255, 255, 0.15);
}
.advantage-block .advantage-item .inclined {
  transform: rotate(-5deg);
}
.advantage-block .advantage-item img {
  display: flex;
  margin-left: 4dvh;
  border-radius: 20px;
  align-self: end;
  object-fit: cover;
  margin-top: -8dvh;
  transition: all 0.9s;
}
.advantage-block .advantage-item:hover {
  transform: scale(1.02);
}
.advantage-block .advantage-item:hover img {
  transform: rotate(0);
  transition: all 0.9s;
}

@media only screen and (max-width: 600px) {
  .last-advantage img {
    transform: rotate(-5deg);
    box-shadow: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/home/advantage_block/style.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;AACD;AACC;EACC,oCAAA;EACA,YAAA;AACF;AAEC;EACC,oCAAA;EACA,YAAA;AAAF;AAIE;EACC,2BAAA;AAFH;AAMC;EACC,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,0CAAA;EACA,qCAAA;EACA,oBAAA;AAJF;AAME;EACC,aAAA;EACA,oBAAA;AAJH;AAOE;EACC,wDAAA;AALH;AAQE;EACC,wBAAA;AANH;AASE;EACC,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,oBAAA;AAPH;AAUE;EACC,sBAAA;AARH;AAUG;EACC,oBAAA;EACA,oBAAA;AARJ;;AAcA;EAEE;IACC,wBAAA;IACA,2BAAA;EAZD;AACF","sourcesContent":[".advantage-block {\n\tcolor: var(--color-white) !important;\n\n\tp {\n\t\tcolor: var(--color-white) !important;\n\t\topacity: 80%;\n\t}\n\n\th6 {\n\t\tcolor: var(--color-white) !important;\n\t\topacity: 80%;\n\t}\n\n\th3 {\n\t\tspan {\n\t\t\tcolor: var(--color-primary);\n\t\t}\n\t}\n\n\t.advantage-item {\n\t\toverflow: hidden;\n\t\tborder-radius: 30px;\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tborder: 1px solid rgba(255, 255, 255, 0.3);\n\t\tbackground: rgba(255, 255, 255, 0.08);\n\t\ttransition: all 0.5s;\n\n\t\t.advantage-img-container {\n\t\t\tdisplay: flex;\n\t\t\tpadding-bottom: 4dvh;\n\t\t}\n\n\t\t.horizontal {\n\t\t\tbox-shadow: -40px 44px 2px 0px rgba(255, 255, 255, 0.15);\n\t\t}\n\n\t\t.inclined {\n\t\t\ttransform: rotate(-5deg);\n\t\t}\n\n\t\timg {\n\t\t\tdisplay: flex;\n\t\t\tmargin-left: 4dvh;\n\t\t\tborder-radius: 20px;\n\t\t\talign-self: end;\n\t\t\tobject-fit: cover;\n\t\t\tmargin-top: -8dvh;\n\t\t\ttransition: all 0.9s;\n\t\t}\n\n\t\t&:hover {\n\t\t\ttransform: scale(1.02);\n\n\t\t\timg {\n\t\t\t\ttransform: rotate(0);\n\t\t\t\ttransition: all 0.9s;\n\t\t\t}\n\t\t}\n\t}\n}\n\n@media only screen and (max-width: 600px) {\n\t.last-advantage {\n\t\timg {\n\t\t\ttransform: rotate(-5deg);\n\t\t\tbox-shadow: none !important;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
