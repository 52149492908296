import "./App.css";
import { BrowserRouter, Navigate, Route, Routes, redirect } from "react-router-dom";
import Home from "./screens/home/Home";
import Navbar from "./components/navigation/navbar/Navbar";
import Footer from "./components/navigation/footer/Footer";
import { useContext, useEffect, useRef } from "react";
import AuthContext from "./contexts/AuthContext";
import Login from "./screens/log/login/Login";
import Register from "./screens/log/register/Register";
import SpecificUser from "./screens/users/specific_user/SpecificUser";
import GenerateQuiz from "./screens/quizzes/generate_quiz/GenerateQuiz";
import PlayQuiz from "./screens/quizzes/play_quiz/PlayQuiz";
import QuizReview from "./screens/quizzes/quiz_review/QuizReview";
import History from "./screens/history/History";
import Pricing from "./screens/pricing/Pricing";
import Profile from "./screens/profile/Profile";
import SuccessPayment from "./screens/payments/success_payment/SuccessPayment";
import Features from "./screens/features/Features";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "./store/dispatcher";
import QuizSpecifications from "./screens/quizzes/quiz_specifications/QuizSpecifications";
import AnonymourRestricted from "./components/general/anonymour_restricted/AnonymourRestricted";
import Ranking from "./screens/quizzes/ranking/Ranking";
import LegalPages from "./screens/legal_pages/LegalPages";
import NotFound from "./screens/not_found/NotFound";
import { App as AntdApp } from "antd";
import VerifyEmail from "./screens/log/verify_email/VerifyEmail";
import ForgotPassword from "./screens/log/forgot-password/ForgotPassword";
import ResetPassword from "./screens/log/reset-password/ResetPassword";

function App({ fetchQuizzes }) {
	const { is_login, is_anonymous_or_login, user } = useContext(AuthContext);
	const loaded = useRef(false);

	const theme = createTheme({
		palette: {
			primary: {
				main: "#f3d800",
			},
			mode: "light",
		},
	});

	useEffect(() => {
		if (!loaded.current && is_login()) {
			fetchQuizzes(user.id);
			loaded.current = true;
		}
	}, [user]);

	return (
		<ThemeProvider theme={theme}>
			<AntdApp>
				<BrowserRouter>
					<Navbar />
					<Routes>
						<Route path="/" element={is_login() ? <QuizSpecifications /> : <Home />} />
						<Route path="/specifications" element={<QuizSpecifications />} />
						<Route path="/pricing" element={<Pricing />} />
						<Route path="/features" element={<Features />} />
						<Route path="/home" element={<Home />} />
						<Route path="/privacy" element={<LegalPages specific_page={24} />} />
						<Route path="/terms" element={<LegalPages specific_page={23} />} />
						<Route path="/quizzes/:id/ranking" element={<Ranking />} />
						{is_anonymous_or_login() ? (
							<>
								<Route path="/quizzes/generate/:id" element={<GenerateQuiz />} />
								<Route path="/quizzes/:id/play" element={<PlayQuiz />} />
								<Route path="/quizzes/:id" element={<QuizReview />} />
								<Route path="/history" element={<History />} />
							</>
						) : null}
						{is_login() ? (
							<>
								<Route path="/profile" element={<Profile />} />
								<Route path="/users/:id/:pseudo" element={<SpecificUser />} />
								<Route path="/payments/success" element={<SuccessPayment />} />
								<Route path="/login" element={<Navigate to={"/"} />} />
								<Route path="/register" element={<Navigate to={"/"} />} />
								<Route path="/*" element={<NotFound />} />
							</>
						) : (
							<>
								<Route path="/login" element={<Login />} />
								<Route path="/verify-email" element={<VerifyEmail />} />
								<Route
									path="/email-verified"
									element={<VerifyEmail verified={true} />}
								/>
								<Route path="/forgot-password" element={<ForgotPassword />} />
								<Route path="/reset-password" element={<ResetPassword />} />
								<Route
									path="/password-reset"
									element={<ResetPassword changed={true} />}
								/>
								<Route path="/register" element={<Register />} />
								<Route path="/*" element={<Navigate to={"/login"} />} />
							</>
						)}
					</Routes>
					<AnonymourRestricted />
					<Footer />
				</BrowserRouter>
			</AntdApp>
		</ThemeProvider>
	);
}

export default connect(null, mapDispatchToProps)(App);
