import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import Circle from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";
import axios from "../../services/axios";
import { useTranslation } from "react-i18next";
import CtaBlock from "../../components/general/cta_block/cta_block";
import LoadingButton from "../../components/main/loading_button/LoadingButton";
import AuthContext from "../../contexts/AuthContext";

const PricingCardInclude = ({ feature }) => {
	return (
		<li className="includes-items">
			<Circle />
			<p>{feature}</p>
		</li>
	);
};

const PricingCard = ({
	title,
	description,
	price,
	price_id,
	features,
	popular,
	button,
	last = false,
	go_to,
	is_current,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation("home");
	const [loading, setLoading] = useState(false);
	const { user } = useContext(AuthContext);

	const generate_link = async () => {
		setLoading(true);
		const ret = await axios
			.get("/payments/customer-portal")
			.then((e) => e.data)
			.catch((e) => null);
		setLoading(false);
		if (ret) {
			window.open(ret.url, "_blank");
		}
	};

	const handleClick = async () => {
		if (!price_id) {
			navigate("/home");
		} else {
			setLoading(true);
			const url = await axios
				.post("/payments", { price_id: price_id })
				.then((r) => r.data)
				.catch((e) => null);
			if (url) {
				window.location.assign(url.url);
			}
			setLoading(false);
		}
	};

	return (
		<div className="pricing-card">
			<div className="pricing-card-header">
				<h2>{title}</h2>
				<p>{description}</p>
				{is_current ? (
					<div className="popular current">
						<span>{t("pricing_current")}</span>
					</div>
				) : popular ? (
					<div className="popular">
						<span>{t("pricing_popular")}</span>
					</div>
				) : null}
			</div>
			<div className="pricing-card-price">
				<span className="big-price">
					{price}
					<span>€</span>
				</span>
				<span className="by-month">/ {t("pricing_month")}</span>
			</div>
			<div className="pricing-card-includes">
				<ul className="includes-container">
					{features.map((elem, index) => (
						<PricingCardInclude feature={elem} key={index} />
					))}
				</ul>
			</div>
			<LoadingButton
				loading={loading}
				onClick={go_to == "pay" ? handleClick : generate_link}
				className={"w-fit pricing " + (last ? "white-button" : "black-button")}
			>
				{go_to == "pay" ? button : t("pricing_manage")}
			</LoadingButton>
		</div>
	);
};

const Pricing = () => {
	const { t } = useTranslation("home", "main");
	const { user } = useContext(AuthContext);
	const [yearlyPaid, setYearlyPaid] = useState(true);

	useEffect(() => {
		document.getElementById("navbar").classList.add("reverse");

		return () => {
			document.getElementById("navbar").classList.remove("reverse");
		};
	}, []);

	return (
		<div id="pricing">
			<section className="home-black-section">
				<div className="container">
					<h6 className={"subtitle section-title-item text-xs text-white"}>{t("main:pricing_bubble")}</h6>
					<h1
						className={"hero-title"}
						dangerouslySetInnerHTML={{
							__html: t("pricing_title", { interpolation: { escapeValue: false } }),
						}}
					></h1>
					<p>{t("pricing_subtitle")}</p>
					<div className="monthly-switch text-white">
						<ul className={"period-option-list"}>
							<li
								onClick={() => setYearlyPaid(!yearlyPaid)}
								className={"period-option " + (yearlyPaid ? "" : "active")}
							>
								{t("pricing_yearly_slide_monthly")}
							</li>
							<li
								onClick={() => setYearlyPaid(!yearlyPaid)}
								className={"period-option " + (yearlyPaid ? "active" : "")}
							>
								{t("pricing_yearly_slide_yearly")}
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="container mb-20 g-section-padding">
				<div className={"pricing-table"}>
					<PricingCard
						title={t("pricing_free_plan_title")}
						description={t("pricing_free_plan_description")}
						price={0}
						button={t("pricing_free_plan_button")}
						features={[
							t("pricing_free_plan_include_1"),
							t("pricing_free_plan_include_2"),
							t("pricing_free_plan_include_3"),
						]}
						go_to={!user.token || user.current_plan == "free" ? "pay" : "manage"}
						is_current={user.current_plan == "free"}
					/>
					<PricingCard
						title={t("pricing_basic_plan_title")}
						description={t("pricing_basic_plan_description")}
						price={yearlyPaid ? 2 : 3}
						popular
						price_id={
							yearlyPaid
								? "price_1NtZChAfqieAunPJCyuFDXWh"
								: "price_1NtZChAfqieAunPJrszO4Kd6"
						}
						button={t("pricing_basic_plan_button")}
						features={[
							t("pricing_basic_plan_include_1"),
							t("pricing_basic_plan_include_2"),
							t("pricing_basic_plan_include_3"),
						]}
						go_to={!user.token || user.current_plan == "free" ? "pay" : "manage"}
						is_current={user.current_plan == "basic"}
					/>
					<PricingCard
						title={t("pricing_unlimited_plan_title")}
						description={t("pricing_unlimited_plan_description")}
						price={yearlyPaid ? 4 : 5}
						price_id={
							yearlyPaid
								? "price_1NtZC9AfqieAunPJTlh8bJFH"
								: "price_1NtZC9AfqieAunPJTYipdVGW"
						}
						button={t("pricing_unlimited_plan_button")}
						features={[
							t("pricing_unlimited_plan_include_1"),
							t("pricing_unlimited_plan_include_2"),
							t("pricing_unlimited_plan_include_3"),
						]}
						last={true}
						go_to={!user.token || user.current_plan == "free" ? "pay" : "manage"}
						is_current={user.current_plan == "unlimited"}
					/>
				</div>
			</section>
			<CtaBlock />
		</div>
	);
};

export default Pricing;
