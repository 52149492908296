// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.daily-limit-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.daily-limit-block .limit-label {
  margin-bottom: 10px;
  margin-top: 15px;
  display: block;
}
.daily-limit-block .limit-block {
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: var(--color-surface);
  border: 1px solid var(--color-divider);
  min-width: 240px;
  width: 50%;
  border-radius: 25px;
  height: 50px;
  overflow: hidden;
  align-items: center;
}
.daily-limit-block .limit-block .bar {
  top: -2px;
  left: 0;
  content: "";
  height: 52px;
  background-color: var(--color-primary);
  border-top: 1px solid var(--color-divider);
  border-bottom: 1px solid var(--color-divider);
  border-right: 1px solid var(--color-divider);
  position: absolute;
  z-index: 0;
  border-radius: 0 26px 26px 0;
}
.daily-limit-block .limit-block span {
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: bold;
  z-index: 1;
  margin-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/profile/daily_limit_block/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,2BAAA;EACA,mBAAA;AACD;AACC;EACC,mBAAA;EACA,gBAAA;EACA,cAAA;AACF;AAEC;EACC,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,sCAAA;EACA,sCAAA;EACA,gBAAA;EACA,UAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AAAF;AAEE;EACC,SAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,sCAAA;EACA,0CAAA;EACA,6CAAA;EACA,4CAAA;EACA,kBAAA;EACA,UAAA;EACA,4BAAA;AAAH;AAGE;EACC,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,UAAA;EACA,iBAAA;AADH","sourcesContent":[".daily-limit-block {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: flex-start;\n\tjustify-content: flex-start;\n\tmargin-bottom: 30px;\n\n\t.limit-label {\n\t\tmargin-bottom: 10px;\n\t\tmargin-top: 15px;\n\t\tdisplay: block;\n\t}\n\n\t.limit-block {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\tposition: relative;\n\t\tbackground-color: var(--color-surface);\n\t\tborder: 1px solid var(--color-divider);\n\t\tmin-width: 240px;\n\t\twidth: 50%;\n\t\tborder-radius: 25px;\n\t\theight: 50px;\n\t\toverflow: hidden;\n\t\talign-items: center;\n\n\t\t.bar {\n\t\t\ttop: -2px;\n\t\t\tleft: 0;\n\t\t\tcontent: \"\";\n\t\t\theight: 52px;\n\t\t\tbackground-color: var(--color-primary);\n\t\t\tborder-top: 1px solid var(--color-divider);\n\t\t\tborder-bottom: 1px solid var(--color-divider);\n\t\t\tborder-right: 1px solid var(--color-divider);\n\t\t\tposition: absolute;\n\t\t\tz-index: 0;\n\t\t\tborder-radius: 0 26px 26px 0;\n\t\t}\n\n\t\tspan {\n\t\t\tfont-size: 0.9rem;\n\t\t\tline-height: 0.9rem;\n\t\t\tfont-weight: bold;\n\t\t\tz-index: 1;\n\t\t\tmargin-left: 30px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
