import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import AuthContext from "../../contexts/AuthContext";
import axios from "../../services/axios";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../components/main/loading_button/LoadingButton";
import { useTranslation } from "react-i18next";
import CtaBlock from "../../components/general/cta_block/cta_block";
import { Edit } from "react-feather";
import DailyLimitBlock from "../../components/profile/daily_limit_block/DailyLimitBlock";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../store/dispatcher";
import { App } from "antd";

const Profile = ({ fetchQuizzes }) => {
	const { user, logout, update_profile } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [editable, setEditable] = useState(false);
	const { notification } = App.useApp();
	const { t } = useTranslation(["home", "main", "messages"]);
	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		password: "",
		new_password: "",
	});
	const navigate = useNavigate();

	function handleEdit() {
		setEditable(!editable);
	}

	const handle_update_profile = async () => {
		setLoading("update-profile");

		const updated_profile = await update_profile({ ...formData });

		if (updated_profile.error) {
			notification.error({
				message: t("messages:error_update_password_current"),
				placement: "bottomLeft",
			});
		} else {
			notification.success({
				message: t("messages:successful_update_profile_message"),
				placement: "bottomLeft",
			});
		}

		setFormData({
			...updated_profile,
			password: "",
			new_password: "",
		});
		setEditable(false);
		setLoading(false);
	};

	useEffect(() => {
		setFormData({
			first_name: user.first_name || "",
			last_name: user.last_name || "",
			password: "",
		});
		document.getElementById("navbar").classList.add("reverse");

		return () => {
			document.getElementById("navbar").classList.remove("reverse");
		};
	}, []);

	useEffect(() => {
		fetchQuizzes(user.id);
	}, [user]);

	const generate_link = async () => {
		setLoading("link-generation");
		const ret = await axios
			.get("/payments/customer-portal")
			.then((e) => e.data)
			.catch((e) => null);
		setLoading(false);
		if (ret) {
			window.open(ret.url, "_blank");
		}
	};

	return (
		<div id="profile">
			<section className="home-black-section g-section-padding">
				<div className="container w-full">
					<div className={"content"}>
						<h6 className={"subtitle section-title-item text-xs text-white"}>
							Profile
						</h6>
						<h1
							className={"hero-title"}
							dangerouslySetInnerHTML={{
								__html: t("profile_title", {
									interpolation: { escapeValue: false },
								}),
							}}
						></h1>
						<p>{t("profile_desc")}</p>
					</div>
				</div>
			</section>
			<section className={"g-section-padding w-full mb-10"}>
				<div
					className={
						"container w-full flex flex-col-reverse lg:flex-row-reverse items-center justify-between gap-4"
					}
				>
					<div className={"personal-info w-full lg:w-1/2"}>
						<div
							className={"flex flex-col lg:flex-row justify-between items-start mb-6"}
						>
							<h2 className={"profile_perso_info_title "}>
								{t("profile_perso_info_title")}
							</h2>
							<div
								className={"white-button"}
								style={{ background: "rgba(0,0,0,0.05)" }}
								onClick={handleEdit}
							>
								{editable ? (
									<span className={"text-xs"}>Cancel </span>
								) : (
									<Edit size={16} />
								)}
							</div>
						</div>
						<form className={""}>
							<div className={"label-line w-full mb-2"}>
								<label id={"name"}>{t("register_first_name_text")}</label>
							</div>
							<input
								disabled={!editable}
								placeholder={t("register_first_name_placeholder_text")}
								value={formData.first_name}
								onChange={(e) =>
									setFormData({ ...formData, first_name: e.target.value })
								}
								type="text"
								autoComplete="name"
							/>
							<div className={"label-line w-full mb-2"}>
								<label id={"name"}>{t("register_last_name_text")}</label>
							</div>
							<input
								disabled={!editable}
								placeholder={t("register_last_name_placeholder_text")}
								value={formData.last_name}
								onChange={(e) =>
									setFormData({ ...formData, last_name: e.target.value })
								}
								type="text"
								autoComplete="name"
							/>

							{editable && user.auth_method != "google" && (
								<>
									<div
										className={
											"label-line flex flex-row justify-between items-center w-full mb-2"
										}
									>
										<label id={"password"}>
											{t("register_current_password_text")}
										</label>
									</div>

									<input
										disabled={!editable}
										placeholder={t("profile_perso_password_label")}
										value={formData.password}
										onChange={(e) =>
											setFormData({ ...formData, password: e.target.value })
										}
										type="password"
										autoComplete="password"
									/>
									<div
										className={
											"label-line flex flex-row justify-between items-center w-full mb-2"
										}
									>
										<label id={"password"}>
											{t("register_new_password_text")}
										</label>
									</div>

									<input
										disabled={!editable}
										placeholder={t("profile_perso_password_label")}
										value={formData.new_password}
										onChange={(e) =>
											setFormData({
												...formData,
												new_password: e.target.value,
											})
										}
										type="password"
										autoComplete="password"
									/>
								</>
							)}
							<div className={"flex flex-col md:flex-row justify-between gap-4"}>
								<LoadingButton
									loading={loading == "update-profile"}
									onClick={handle_update_profile}
									disabled={!editable}
									className={"w-fit main-button"}
								>
									{t("profile_perso_save_btn")}
								</LoadingButton>
								<div
									className={"white-button"}
									onClick={logout}
									style={{ background: "rgba(0,0,0,0.05)", color: "red" }}
								>
									{t("profile_logout_button")}
								</div>
							</div>
						</form>
					</div>
					<div className={"subscrition-info my-8 lg:my-0 flex flex-col w-full lg:w-1/2"}>
						<h4
							dangerouslySetInnerHTML={{
								__html: t("profile_plan_title", {
									interpolation: { escapeValue: false },
								}),
							}}
						></h4>
						<h1 className={"text-primary_yellow mb-4"}>
							{t(`pricing_${user.current_plan}_plan_title`)}
						</h1>

						<LoadingButton
							className={"black-button mb-10"}
							onClick={
								user.subscription_id ? generate_link : () => navigate("/pricing")
							}
							loading={loading == "link-generation"}
						>
							{t("profile_edit_btn")}
						</LoadingButton>
						<DailyLimitBlock />
						<p>{t("profile_plan_explain")}</p>
					</div>
				</div>
			</section>
			<CtaBlock />
		</div>
	);
};

export default connect(null, mapDispatchToProps)(Profile);
