import React, { useState, useEffect, useContext } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../contexts/AuthContext";

function KPIItem({ min, real, display, increment = 1, description }) {
	const [currentNumber, setCurrentNumber] = useState(real);
	const number = real;
	const duration = 2;

	// number displayed by component
	const [count, setCount] = useState("0");

	// calc time taken for computation
	const [timeTaken, setTimeTaken] = useState(Date.now());

	useEffect(() => {
		let start = min;
		// first three numbers from props
		const end = parseInt(number);
		// if zero, return
		if (start === end) return;

		// find duration per increment
		let totalMilSecDur = parseInt(duration);
		let incrementTime = (totalMilSecDur / end) * 1000;

		// timer increments start counter
		// then updates count
		// ends if start reaches end
		let timer = setInterval(() => {
			start += increment;
			setCount(String(start));

			if (start === end) {
				clearInterval(timer);
				const diff = Date.now() - timeTaken;
				setTimeTaken(diff / 1000);
			}
		}, incrementTime);

		// dependency array
	}, [number, duration]);

	return (
		<div className={"kpi-item flex flex-col align-start justify-start w-2/3 h-fit p-6"}>
			<h2>
				{" "}
				<span className={"text-text_color text-2xl"}>+</span>
				{number !== count ? count : display}
			</h2>
			<p className={"flex flex-wrap"}>{description}</p>
		</div>
	);
}

const KPIBlock = ({ blackBG = false }) => {
	const { t } = useTranslation(["home", "main"]);
	const { is_login } = useContext(AuthContext);

	return (
		<div
			className={
				"kpi-block grid grid-cols-2 lg:grid-cols-4 gap-4 flex-row w-full h-fit justify-center"
			}
		>
			<KPIItem
				min={999000}
				increment={100}
				real={1000000}
				display={"01 Million"}
				description={t("KPI_desc_1")}
			/>
			<KPIItem min={0} increment={1} real={8} display={"08"} description={t("KPI_desc_2")} />
			<KPIItem
				min={500}
				increment={1}
				real={1800}
				display={"1.8k"}
				description={t("KPI_desc_3")}
			/>
			<div className={"key-cta flex bg-black flex-col p-6"}>
				<h3 className={"text-white"}>{t("KPI_cta_title")}</h3>
				<p className={"text-white text-xs mt-1 mb-3 opacity-75"}> {t("KPI_cta_desc")} </p>
				{is_login() ? (
					<Link to={"/"}>
						<button
							className={"text-xs " + (blackBG ? "black-button" : "white-button")}
						>
							<span>{t("main:get_started_now")}</span>
						</button>
					</Link>
				) : (
					<Link to={"/register"}>
						<button
							className={"text-xs " + (blackBG ? "black-button" : "white-button")}
						>
							<span>{t("main:register_now")}</span>
						</button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default KPIBlock;
