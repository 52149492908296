import React, { useEffect, useState } from "react";
import { ChevronLeft } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { useTranslation } from "react-i18next";
import axios from "../../../services/axios";
import LoadingButton from "../../../components/main/loading_button/LoadingButton";
import { Slider } from "@mui/material";
import { get_data_list } from "../../../helpers/content_manager";
import { App } from "antd";

const QuizSpecifications = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation("quizzes");
	const { state } = useLocation();
	const [quiz, setQuiz] = useState({
		title: state?.title ? state.title : "",
		difficulty: 4,
		number_of_questions: 5,
		context: "",
	});
	const [generalQuestions, setGeneralQuestions] = useState([]);
	const spec_view = window.location.href.includes("/specifications");
	const [loading, setLoading] = useState(false);
	const { notification } = App.useApp();

	const middleIndex = Math.floor(generalQuestions.length / 2);

	const line1Items = generalQuestions.slice(0, middleIndex);
	const line2Items = generalQuestions.slice(middleIndex);

	const generate_quiz = async () => {
		setLoading(true);
		const generated = await axios
			.post(`/quizzes`, {
				number_of_questions: quiz.number_of_questions,
				topic: quiz.title,
				difficulty: quiz.difficulty,
				context: quiz.context,
			})
			.then((r) => r.data)
			.catch((e) => null);
		if (generated != null) {
			setQuiz({
				title: "",
				difficulty: 2,
				number_of_questions: 5,
				context: "",
			});
			setLoading(false);
			navigate(`/quizzes/generate/${generated.id}`);
		} else {
			setLoading(false);
			notification.error({
				message: t("messages:error_custom_quiz_daily_limit_message"),
				description: t("messages:error_custom_quiz_daily_limit_description"),
				placement: "bottomLeft",
			});
		}
	};

	function fieldInput(text = "test") {
		setQuiz({ ...quiz, title: text });
	}

	useEffect(() => {
		(async function load_questions() {
			const questions = await get_data_list("general_question", i18n.language);
			setGeneralQuestions(questions);
		})();
	}, [i18n.language]);

	useEffect(() => {
		if (spec_view) {
			document.getElementsByTagName("header")[0].style.display = "none";
		}
		document.querySelector(".mobile-header").classList.add("on-light");
		window.scrollTo(0, 0);
		return () => {
			document.getElementsByTagName("header")[0].style.display = "flex";

			document.querySelector(".mobile-header").classList.remove("on-light");
		};
	}, []);

	return (
		<div id="quiz-specification" className={spec_view ? "" : "home"}>
			<section className="home-white-section" style={{ paddingTop: "4vw" }}>
				<div
					className={
						"g-section-padding w-full flex self-center items-center justify-center"
					}
				>
					<div className="flex w-full justify-between flex-col md:flex-row gap-2 items-center">
						{spec_view ? (
							<button
								className={
									"rounded-full self-start md:self-center p-2 md:p-3 opacity-60"
								}
								onClick={() => navigate(-1)}
								style={{
									border: "1px solid rgba(0, 0, 0, 0.15)",
									background: "rgba(0, 0, 0, 0.10)",
									backdropFilter: "blur(2px)",
								}}
							>
								<ChevronLeft color={"#909090"} size={25} />
							</button>
						) : (
							<div></div>
						)}
						<div className={"flex flex-col items-center justify-center"}>
							<div>
								<h1 className={"mb-6"} style={{ marginBottom: 0 }}>
									{t("specification_title")}
								</h1>
							</div>
							<span className={"text-lg"}>{t("specification_description")}</span>
						</div>
						<div></div>
					</div>
				</div>
				<div className="generator-container">
					<div className="quiz-generation-block">
						<span className="shortest text-center">
							{t("home:home_mainbox_fast_text")}
						</span>
						<h2 className="big text-center">{t("home:home_mainbox_title")}</h2>
						<span className="short text-center">
							{t("home:home_mainbox_explaination")}
						</span>
						<input
							id={"questionTitle"}
							value={quiz.title}
							className="cust-input"
							onChange={(e) => setQuiz({ ...quiz, title: e.target.value })}
							placeholder={t("home:home_mainbox_title_input")}
							style={{ width: 100 + "%" }}
						/>
						<div
							className={
								"animated-text flex flex-col justify-start align-top mt-1 mb-8"
							}
						>
							<div className="line">
								{line1Items.concat(line1Items).map((item, index) => (
									<span
										onClick={() => fieldInput(item.fields.title)}
										className={"history-question-item cursor-pointer"}
										key={index}
									>
										{item.fields.title}
									</span>
								))}
							</div>
							<div className="line reverse">
								{line2Items.concat(line2Items).map((item, index) => (
									<span
										onClick={() => fieldInput(item.fields.title)}
										className={"history-question-item cursor-pointer"}
										key={index}
									>
										{item.fields.title}
									</span>
								))}
							</div>
						</div>
						<div className={"flex flex-col md:flex-row align-center justify-center"}>
							<LoadingButton
								disabled={quiz.title == ""}
								className={"main-button ml-0 md:ml-6 mt-4 md:mt-0"}
								onClick={generate_quiz}
								loading={loading}
							>
								{t("home:home_generate_quiz")}
							</LoadingButton>
						</div>
					</div>
					<div className="generator-spec">
						<h2>{t("specification_box_title")}</h2>
						<div className="specification-row">
							<span>{t("number_of_questions")}: </span>
							<div className="add-questions">
								<div
									onClick={() => {
										if (quiz.number_of_questions > 1)
											setQuiz({
												...quiz,
												number_of_questions: quiz.number_of_questions - 1,
											});
									}}
									className="plus-minus-button"
								>
									-
								</div>
								<span>{quiz.number_of_questions.toString().padStart(2, "0")}</span>
								<div
									onClick={() => {
										if (quiz.number_of_questions < 10)
											setQuiz({
												...quiz,
												number_of_questions: quiz.number_of_questions + 1,
											});
									}}
									className="plus-minus-button"
								>
									+
								</div>
							</div>
						</div>
						<div className="specification-row">
							<span>{t("difficulty_level")}: </span>
							<Slider
								className="slider"
								defaultValue={2}
								onChange={(e) => setQuiz({ ...quiz, difficulty: e.target.value })}
								min={0}
								max={4}
								step={1}
								marks={[
									{
										value: 0,
										label: t("easy"),
									},
									{
										value: 1,
										label: t("normal"),
									},
									{
										value: 2,
										label: t("intermediate"),
									},
									{
										value: 3,
										label: t("difficult"),
									},
									{
										value: 4,
										label: t("Expert"),
									},
								]}
							/>
						</div>
						<div className="specification-row">
							<span>{t("additional_information_label")}: </span>
							<textarea
								onChange={(e) => setQuiz({ ...quiz, context: e.target.value })}
								className="aditional-informations"
								placeholder={t("additional_information_placeholder")}
							></textarea>
						</div>
					</div>
				</div>
				<div className="semi-section-black"></div>
			</section>
		</div>
	);
};

export default QuizSpecifications;
