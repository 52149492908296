import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import axios from "../../services/axios";
import AuthContext from "../../contexts/AuthContext";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../components/main/loading_button/LoadingButton";
import { ArrowLeft, ArrowRight } from "react-feather";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";

const difficulty = ["easy", "normal", "intermediate", "hard", "expert"];

const History = ({ fetchQuizzes, quizzes }) => {
	const { t } = useTranslation(["home", "main", "history"]);

	const { user, profile } = useContext(AuthContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	// For pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(5);

	// For responsive
	const [isMobile, setIsMobile] = useState(false);

	const handleClick = async () => {
		setLoading(true);
		const response = await axios
			.post("/users/restore-history")
			.then((e) => e.data)
			.catch((e) => null);
		if (response) {
			await fetchQuizzes(user.id);
		}
		setLoading(false);
	};

	const lastItemIndex = currentPage * itemsPerPage;
	const firstItemIndex = lastItemIndex - itemsPerPage;
	const currentItems = quizzes.history?.slice(firstItemIndex, lastItemIndex);
	const totalPages = Math.ceil(quizzes.history?.length / itemsPerPage);

	useEffect(() => {
		fetchQuizzes(user.id);
		const checkMobile = () => {
			setIsMobile(window.innerWidth < 768);
		};
		checkMobile();
		window.addEventListener("resize", checkMobile);

		document.querySelector(".mobile-header").classList.add("on-light");
		return () => {
			window.removeEventListener("resize", checkMobile);

			document.querySelector(".mobile-header").classList.remove("on-light");
		};
	}, []);

	return (
		<div id="history">
			<section className="top relative flex justify-center items-center g-section-padding mb-20">
				<div
					className={
						"hero-container flex items-center flex-col md:w-3/4 lg:w-1/2 mt-10 mb-10 gap-2"
					}
				>
					<h1 className={"hero-title-black"}>{t("history_section_title")}</h1>
					<p className={"text-center"}>{t("history_section_desc")}</p>

					<LoadingButton
						loading={loading}
						onClick={handleClick}
						className={"black-button mt-2"}
					>
						{t("history_reset_btn")}
					</LoadingButton>
				</div>
				<div className="container history-container ">
					{currentItems?.map((elem, index) => (
						<div
							className="history-item flex-col md:flex-row justify-start md:justify-between items-start md:items-center text-left gap-2"
							key={index}
						>
							<div className="left-side">
								<h4>{elem.title}</h4>
							</div>
							<div
								className={
									"right-side flex flex-row gap-4 md:gap-8 items-center md:items-center justify-between md:justify-center"
								}
							>
								<span className={""}>
									{elem.ended ? (
										<span className={"opacity-50"}> score: </span>
									) : (
										<span className={"opacity-50"}> No score</span>
									)}
									{elem.ended
										? `${elem.correct_answers} / ${elem.question_count}`
										: ""}
								</span>
								<button
									onClick={() => {
										elem.current_question === elem.question_count
											? navigate(`/quizzes/${elem.quiz_id}`)
											: navigate(`/quizzes/${elem.quiz_id}/play`);
									}}
									variant="outlined"
									className={
										elem.current_question === elem.question_count
											? "black-button"
											: "main-button"
									}
								>
									{elem.current_question === elem.question_count
										? t("history:check_result")
										: t("history:resume_quiz")}
								</button>
							</div>
						</div>
					))}
					{quizzes.history == null ? (
						<>
							<Skeleton className="squeleton" variant="rectangular" />
							<Skeleton className="squeleton" variant="rectangular" />
							<Skeleton className="squeleton" variant="rectangular" />
							<Skeleton className="squeleton" variant="rectangular" />
							<Skeleton className="squeleton" variant="rectangular" />
						</>
					) : quizzes.history.length == 0 ? (
						<div className="no-quizzes">
							<span>Vous n'avez pas encore genere de quiz</span>
							<button
								className={"main-button"}
								onClick={() => navigate("/specifications")}
								size="large"
								variant="outlined"
							>
								{t("main:get_started_now")}
							</button>
						</div>
					) : null}
				</div>
				<div
					className={
						"pagination-container flex flex-row flex-wrap items-center justify-center gap-4 mt-14"
					}
				>
					{isMobile ? (
						<button
							disabled={currentPage === 1}
							className={"pagination-ico-btn black-button border-2"}
							onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
						>
							<ArrowLeft size={18} color={currentPage === 1 ? "silver" : "white"} />
						</button>
					) : (
						<button
							className={"black-button"}
							onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
							disabled={currentPage === 1}
						>
							{t("history_prev_btn")}
						</button>
					)}
					{totalPages > 5 ? (
						<>
							<span
								className={
									"p-4 rounded-full border-2 flex items-center justify-center"
								}
								onClick={() => setCurrentPage(1)}
								style={{
									borderColor: currentPage === 1 ? "black" : "silver",
									color: currentPage === 1 ? "black" : "silver",
								}}
							>
								1
							</span>
							{currentPage > 2 && (
								<span
									className={
										"p-4 rounded-full border-2 flex items-center justify-center"
									}
									style={{ borderColor: "silver", color: "silver" }}
								>
									...
								</span>
							)}
							{currentPage !== 1 && currentPage !== totalPages && (
								<span
									className={
										"p-4 rounded-full border-2 flex items-center justify-center"
									}
									style={{
										borderColor: "black",
										color: "black",
									}}
								>
									{currentPage}
								</span>
							)}
							{currentPage < totalPages - 1 && (
								<span
									className={
										"p-4 rounded-full border-2 flex items-center justify-center"
									}
									style={{
										cursor: "pointer",
										borderColor: "silver",
										color: "silver",
									}}
								>
									...
								</span>
							)}
							<span
								className={
									"p-4 rounded-full border-2 flex items-center justify-center"
								}
								onClick={() => setCurrentPage(totalPages)}
								style={{
									width: "60px",
									cursor: "pointer",
									borderColor: currentPage === totalPages ? "black" : "silver",
									color: currentPage === totalPages ? "black" : "silver",
								}}
							>
								{totalPages}
							</span>
						</>
					) : (
						Array.from({ length: totalPages }, (_, index) => (
							<span
								className={
									"p-4 rounded-full border-2 flex items-center justify-center"
								}
								key={index}
								onClick={() => setCurrentPage(index + 1)}
								style={{
									width: "60px",
									cursor: "pointer",
									borderColor: currentPage === index + 1 ? "black" : "silver",
									color: currentPage === index + 1 ? "black" : "silver",
								}}
							>
								{index + 1}
							</span>
						))
					)}
					{isMobile ? (
						<button
							disabled={currentPage === totalPages}
							className={"pagination-ico-btn black-button border-2"}
							onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
						>
							<ArrowRight
								size={18}
								color={currentPage === totalPages ? "silver" : "white"}
							/>
						</button>
					) : (
						<button
							className={"black-button"}
							onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
							disabled={currentPage === totalPages}
						>
							{t("history_next_btn")}
						</button>
					)}
				</div>
			</section>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
