import React, { useEffect, useState } from "react";
import "./style.scss";
import { get_data_item } from "../../helpers/content_manager";
import { useTranslation } from "react-i18next";

const LegalPages = ({ specific_page }) => {
	const [pageContent, setPageContent] = useState("");
	const { i18n } = useTranslation();

	const load_content = async () => {
		const content = await get_data_item("specific_page", specific_page, i18n.language);
		if (content) {
			setPageContent(content.fields.content);
		}
	};

	useEffect(() => {
		load_content();
		window.scrollTo({ top: 0 });
	}, [specific_page, i18n.language]);

	return (
		<div className="legal-page">
			<section>
				<div className="container" dangerouslySetInnerHTML={{ __html: pageContent }}></div>
			</section>
		</div>
	);
};

export default LegalPages;
