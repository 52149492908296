// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partner-block {
  overflow-x: hidden;
}

.logo-slider::-webkit-scrollbar {
  display: none;
}

.logo {
  max-width: 150px;
  max-height: 50px;
  margin: 0 5px;
  display: inline-block;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/home/partner_block/style.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;AAAF","sourcesContent":["\n.partner-block{\n  overflow-x: hidden;\n}\n\n.logo-slider::-webkit-scrollbar {\n  display: none;\n}\n\n.logo {\n  max-width: 150px;\n  max-height: 50px;\n  margin: 0 5px;\n  display: inline-block;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
