import React, { useContext } from "react";
import "./style.scss";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/dispatcher";
import AuthContext from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const create_max = {
	free: 5,
	basic: 20,
	unlimited: -1,
};

const replay_max = {
	free: 20,
	basic: -1,
	unlimited: -1,
};

const LimitBlock = ({ value, max }) => {
	const max_label = max < 0 ? "∞" : max;
	const width = (max < 0 ? 0 : value) / max;

	return (
		<div className="limit-block">
			{value != 0 && width != 0 && (
				<div className="bar" style={{ width: `${width * 100}%` }}></div>
			)}
			<span>
				{value} / {max_label}
			</span>
		</div>
	);
};

const DailyLimitBlock = ({ quizzes }) => {
	const { user } = useContext(AuthContext);
	const { t, i18n } = useTranslation("home", "main");
	const counting_quizzes = quizzes?.history?.filter(
		(quiz) => Date.now() - new Date(quiz.started_at).getTime() < 24 * 1000 * 3600
	);
	if (!counting_quizzes) {
		return null;
	}
	const created = counting_quizzes.filter((quiz) => quiz.already_exist == false).length;
	const replay = counting_quizzes.length - created;
	const max_create = create_max[user.current_plan];
	const max_replay = replay_max[user.current_plan];

	return (
		<div className="daily-limit-block">
			<span className="limit-label">{t("profile_custom_quiz")}</span>
			<LimitBlock value={created} max={max_create} />
			<span className="limit-label">{t("profile_existing_quiz")}</span>
			<LimitBlock value={replay} max={max_replay} />
		</div>
	);
};

export default connect(mapStateToProps, null)(DailyLimitBlock);
