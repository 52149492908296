// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-line .step-process-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 25px;
  border-radius: 20px;
}
.step-line .step-process-item > span {
  opacity: 0.1;
  position: absolute;
  font-weight: bold;
  font-size: 3rem;
  transform: translateY(-6px);
}
.step-line .step-process-item h3 {
  margin-top: 3dvh;
}
.step-line .step-process-item h3 span {
  color: var(--color-primary);
}
.step-line .next-indicator {
  width: 3.125rem;
  height: 3.125rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  background: rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/home/process_block/style.scss"],"names":[],"mappings":"AACC;EACC,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,oCAAA;EACA,kBAAA;EACA,mBAAA;AAAF;AAEE;EACC,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,2BAAA;AAAH;AAGE;EACC,gBAAA;AADH;AAGG;EACC,2BAAA;AADJ;AAMC;EACC,eAAA;EACA,gBAAA;EACA,cAAA;EACA,uBAAA;EACA,8BAAA;AAJF","sourcesContent":[".step-line {\n\t.step-process-item {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tjustify-content: flex-start;\n\t\tborder: 1px solid rgba(0, 0, 0, 0.1);\n\t\tpadding: 25px 25px;\n\t\tborder-radius: 20px;\n\n\t\t> span {\n\t\t\topacity: 0.1;\n\t\t\tposition: absolute;\n\t\t\tfont-weight: bold;\n\t\t\tfont-size: 3rem;\n\t\t\ttransform: translateY(-6px);\n\t\t}\n\n\t\th3 {\n\t\t\tmargin-top: 3dvh;\n\n\t\t\tspan {\n\t\t\t\tcolor: var(--color-primary);\n\t\t\t}\n\t\t}\n\t}\n\n\t.next-indicator {\n\t\twidth: 3.125rem;\n\t\theight: 3.125rem;\n\t\tflex-shrink: 0;\n\t\tborder-radius: 0.625rem;\n\t\tbackground: rgba(0, 0, 0, 0.2);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
