// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#anonoymous_restricted {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 100;
  background-color: var(--color-primary);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
}
#anonoymous_restricted span {
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center;
}
#anonoymous_restricted .timer {
  font-size: 1.4rem;
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/general/anonymour_restricted/style.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,sCAAA;EACA,6BAAA;EACA,mCAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AACD;AACC;EACC,WAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAEC;EACC,iBAAA;EACA,gBAAA;AAAF","sourcesContent":["#anonoymous_restricted {\n\tposition: fixed;\n\tright: 40px;\n\tbottom: 40px;\n\tz-index: 100;\n\tbackground-color: var(--color-primary);\n\tbox-shadow: var(--box-shadow);\n\tborder-radius: var(--border-radius);\n\tpadding: 10px 20px;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tmax-width: 300px;\n\n\tspan {\n\t\tcolor: #fff;\n\t\tfont-weight: bold;\n\t\tfont-size: 1rem;\n\t\tmargin-bottom: 10px;\n\t\ttext-align: center;\n\t}\n\n\t.timer {\n\t\tfont-size: 1.4rem;\n\t\tmargin-bottom: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
