import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CtaBlock = () => {
	const { t, i18n } = useTranslation("main", "main");

	return (
		<div
			className={
				"cta-block w-full text-center justify-center items-center flex flex-col g-section-padding shapedBackground bg-primary_yellow pb-20 pt-20"
			}
		>
			<h1
				dangerouslySetInnerHTML={{ __html: t("cta_title") }}
				className={"text-center "}
			></h1>
			<p className={"text-black mt-2 mb-2"}>{t("cta_description")}</p>
			<Link to={"/specifications"} className={"white-button mt-2"}>
				{t("cta_button_text")}
			</Link>
		</div>
	);
};

export default CtaBlock;
