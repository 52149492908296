import React, { useEffect } from "react";
import "./style.scss";
import { Navigate } from "react-router-dom";
import { App } from "antd";
import { useTranslation } from "react-i18next";

const SuccessPayment = () => {
	const { notification } = App.useApp();
	const { t } = useTranslation("messages");

	useEffect(() => {
		notification.success({
			message: t("successful_payment_message"),
			description: t("successful_payment_description"),
			placement: "bottomLeft",
		});
	}, []);

	return <Navigate to="/profile" />;
};

export default SuccessPayment;
