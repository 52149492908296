import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import "../login/style.scss";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../components/main/loading_button/LoadingButton";
import { App } from "antd";
import AuthPresentation from "../../../components/auth/auth_presentation/AuthPresentation";
import axios_auth from "../../../services/auth_axios";

const ResetPassword = ({ changed = false }) => {
	const [loading, setLoading] = useState(false);
	const { notification } = App.useApp();
	let [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		password: "",
		password_confirmation: "",
	});

	const { t } = useTranslation(["home", "main", "messages"]);

	const handleClick = async (e) => {
		e.preventDefault();
		setLoading(true);
		const reset = await axios_auth
			.put("/users/forgot-password", {
				email: searchParams.get("email"),
				code: searchParams.get("code"),
				new_password: formData.password,
			})
			.then((e) => e.data)
			.catch((e) => null);

		setLoading(false);
		if (!reset) {
			notification.error({
				message: t("messages:error_reset_message"),
				description: t("messages:error_reset_description"),
				placement: "bottomLeft",
			});
		} else {
			navigate("/password-reset");
		}
		setFormData({ email: "" });
	};
	// For mobile header color
	useEffect(() => {
		document.querySelector(".mobile-header").classList.add("on-light");
		return () => {
			document.querySelector(".mobile-header").classList.remove("on-light");
		};
	}, []);

	if (changed) {
		return (
			<section id="verify-email">
				<section className={"container for-log g-section-padding mb-20"}>
					<div className={"form-container flex flex-col gap-8 w-full lg:w-1/2"}>
						<div className={"text-container flex flex-col gap-2"}>
							<h2 className={"section-title"}>{t("password_reset_successfully")}</h2>
							<p className={"opacity-80 text-sm"}>{t("you_can_now_login")}</p>
						</div>
						<form className={""}>
							<LoadingButton loading={loading} onClick={() => navigate("/login")}>
								{t("login_button_text")}
							</LoadingButton>
						</form>
					</div>
					<AuthPresentation />
				</section>
			</section>
		);
	}

	return (
		<section id="verify-email">
			<section className={"container for-log g-section-padding mb-20"}>
				<div className={"form-container flex flex-col gap-8 w-full lg:w-1/2"}>
					<div className={"text-container flex flex-col gap-2"}>
						<h2
							className={"section-title"}
							dangerouslySetInnerHTML={{
								__html: t("reset_password", {
									interpolation: { escapeValue: false },
								}),
							}}
						></h2>
						<p
							className={"opacity-80 text-sm"}
							dangerouslySetInnerHTML={{
								__html: t("reset_password_desc", {
									email: searchParams.get("email"),
									interpolation: { escapeValue: false },
								}),
							}}
						></p>
					</div>
					<form className={""}>
						<div className={"label-line w-full mb-2"}>
							<label id={"email"}>{t("login_password_text")}</label>
						</div>
						<input
							placeholder={t("login_password_placeholder_text")}
							value={formData.password}
							onChange={(e) => setFormData({ ...formData, password: e.target.value })}
							type="password"
						/>
						<div className={"label-line w-full mb-2"}>
							<label id={"email"}>{t("password_confirmation_label")}</label>
						</div>
						<input
							placeholder={t("password_confirmation_placeholder")}
							value={formData.password_confirmation}
							onChange={(e) =>
								setFormData({ ...formData, password_confirmation: e.target.value })
							}
							type="password"
						/>

						<LoadingButton button={true} loading={loading} onClick={handleClick}>
							{t("reset_password_btn")}
						</LoadingButton>
					</form>
				</div>
				<AuthPresentation />
			</section>
		</section>
	);
};

export default ResetPassword;
