import React from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../general/section_title/section_title";
import process_img from "../../../assets/images/Process_img.png";
import rightArrow from "../../../assets/icones/corner-right.svg";
import leftArrow from "../../../assets/icones/corner-left.svg";

function StepProcessItem({ number, title, desc, index, max = 3 }) {
	const { t } = useTranslation(["home", "main"]);

	let pos = "";
	let position = "";
	let flexType = "";
	let arrow;
	let nextStepDisplay;
	let startTestDisplay;

	if (index % 2 !== 0) {
		pos = "end";
		position = "right";
		flexType = "row-reverse";
		arrow = leftArrow;
	} else {
		pos = "start";
		position = "left";
		flexType = "row";
		arrow = rightArrow;
	}

	if (index !== max - 1) {
		nextStepDisplay = "flex";
		startTestDisplay = "none";
	} else {
		nextStepDisplay = "none";
		startTestDisplay = "flex";
	}

	const navigate = useNavigate();
	const generate_quiz = () => {
		navigate("/quizzes/generate", {});
	};

	return (
		<div
			className={
				"step-line w-full flex flex-row items-center justify-between m-0 mb-10 sm:mt-6"
			}
			style={{ flexDirection: flexType }}
		>
			<div
				className={"step-process-item w-full md:w-2/3"}
				style={{ textAlign: position, alignItems: pos }}
			>
				<span>{number}</span>
				<h3 dangerouslySetInnerHTML={{ __html: t(title) }}></h3>
				<p>{t(desc)}</p>
			</div>
			<div
				className={"next-indicator h-fit w-fit m-4 p-2 " + position}
				style={{ display: nextStepDisplay }}
			>
				<img alt={"step"} className={"w-full h-full"} src={arrow}></img>
			</div>
			<button
				className={"main-button self-end ml-2 mr-2"}
				onClick={() => window.scrollTo({ top: 250, left: 0, behavior: "smooth" })}
				style={{ display: startTestDisplay }}
			>
				{t("main:cta_button_text")}
			</button>
		</div>
	);
}

const ProcessBlock = () => {
	const { t, i18n } = useTranslation("home", "main");

	const stepList = [
		{
			number: "01",
			title: "home_process_1_title",
			desc: "home_process_1_description",
		},
		{
			number: "02",
			title: "home_process_2_title",
			desc: "home_process_1_description",
		},
		{
			number: "03",
			title: "home_process_3_title",
			desc: "home_process_1_description",
		},
	];

	return (
		<div className={"w-full flex-col h-fit mt-20 mb-40"}>
			<SectionTitle
				position={"left"}
				subtitle={t("process_section_subtitle")}
				title={t("process_section_title")}
				description={t("process_section_desc")}
			></SectionTitle>
			<div className={"flex w-full md:flex-row flex-col mt-20 md:justify-around"}>
				<div
					className={
						" inclined-container p-4 md:p-0 flex h-fit md:sticky top-40 w-full md:w-1/2 xl:w-1/3 justify-center items-center mr-0 md:mr-20 mb-20 md:mb-0"
					}
				>
					<img
						className={"inclined-img"}
						alt={t("process_section_img_alt")}
						src={process_img}
					/>
				</div>
				<div
					className={"step-container w-full md:w-1/2 xl:md-2/3 justify-start items-start"}
				>
					{stepList.map((item, index) => (
						<StepProcessItem
							key={index}
							className={"w-full mr-0 md:mr-6 "}
							index={index}
							desc={item.desc}
							title={item.title}
							number={item.number}
							max={stepList.length}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProcessBlock;
