// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loading-main-view {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
  margin: 30px 0;
  padding-top: 80px;
}
#loading-main-view .lotties {
  width: 200px;
  height: 200px;
}
#loading-main-view.full-screen {
  height: calc(100vh - 60px);
  padding-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/main/loading/style.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;EACA,cAAA;EACA,iBAAA;AACD;AACC;EACC,YAAA;EACA,aAAA;AACF;AAEC;EACC,0BAAA;EACA,iBAAA;AAAF","sourcesContent":["#loading-main-view {\n\twidth: 100%;\n\theight: 80vh;\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n\tjustify-content: center;\n\tpadding-bottom: 60px;\n\tmargin: 30px 0;\n\tpadding-top: 80px;\n\n\t.lotties {\n\t\twidth: 200px;\n\t\theight: 200px;\n\t}\n\n\t&.full-screen {\n\t\theight: calc(100vh - 60px);\n\t\tpadding-bottom: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
