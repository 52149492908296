// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legal-page {
  padding-top: 180px;
  padding-bottom: 120px;
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/screens/legal_pages/style.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,qBAAA;EACA,iBAAA;AACD","sourcesContent":[".legal-page {\n\tpadding-top: 180px;\n\tpadding-bottom: 120px;\n\tmin-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
