import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import axios from "../../../services/axios";
import AuthContext from "../../../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/main/loading/Loading";
import { useTranslation } from "react-i18next";
import AnswerItem from "../../../components/quizzes/answer_item/answer_item";
import { ChevronLeft } from "react-feather";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/dispatcher";

const ReviewItem = ({ question, answer }) => {
	return (
		<div className="review-item flex flex-col lg:flex-row gap-4 lg:gap-8">
			<div className="review-left justify-center w-full lg:w-1/2 flex flex-col gap-2">
				<h4>{question.question}</h4>
				<span className={"explanation"}>{question.short_explanation}</span>
			</div>
			<div className="review-right w-full lg:w-1/2">
				{question.answers.map((elem, id) => (
					<AnswerItem
						state={`${elem.id === question.correct_answer ? "correct" : ""} ${
							elem.id === answer ? "failed" : ""
						}`}
						key={id}
						text={elem.answer}
						number={id}
					></AnswerItem>
				))}
			</div>
		</div>
	);
};

const QuizReview = ({ quizzes }) => {
	const { id } = useParams();
	const quiz_from_history = quizzes.history?.filter((elem) => elem.quiz_id == id);

	const [currentQuiz, setCurrentQuiz] = useState(
		quiz_from_history?.length ? quiz_from_history[0] : null
	);
	const { user } = useContext(AuthContext);

	const { t, i18n } = useTranslation("home", "main");
	const navigate = useNavigate();

	// For sharing
	const currentUrl = window.location.href;
	const shareUrl = currentUrl.endsWith("/") ? currentUrl + "play" : currentUrl + "/play";

	const load_current_quiz = async () => {
		const quiz = await axios
			.get(`/users/${user.id}/quizzes/${id}`)
			.then((r) => r.data)
			.catch((e) => null);
		if (quiz) {
			if (!quiz.user_quiz.ended) {
				navigate(`/quizzes/${id}/play`);
			} else {
				setCurrentQuiz({
					...quiz.quiz,
					current_question: quiz.user_quiz.current_question,
					ended: quiz.user_quiz.ended,
					answers: quiz.user_quiz.answers,
				});
			}
		}
	};

	useEffect(() => {
		document.getElementsByTagName("header")[0].style.display = "none";
		load_current_quiz();
		window.scrollTo(0, 0);
		return () => {
			document.getElementsByTagName("header")[0].style.display = "flex";
		};
	}, []);

	if (!currentQuiz) {
		return <Loading />;
	}

	return (
		<div id="quiz-review">
			<section className="home-black-section" style={{ paddingTop: "4vw" }}>
				<div
					className={
						"g-section-padding w-full flex self-center items-center justify-center"
					}
				>
					<div className="flex w-full justify-between flex-col md:flex-row gap-2 items-center">
						<button
							className={
								"rounded-full self-start md:self-center p-2 md:p-3 opacity-60"
							}
							onClick={() => navigate("/history")}
							style={{
								border: "1px solid rgba(255, 255, 255, 0.60)",
								background: "rgba(255, 255, 255, 0.50)",
								backdropFilter: "blur(2px)",
							}}
						>
							<ChevronLeft color={"white"} size={25} />
						</button>
						<div className={"flex flex-col items-center justify-center"}>
							<div>
								<Link to="/">
									<span className="big-title">
										Quiz<span>Battle</span>
									</span>
								</Link>
							</div>
							<h1 className={"mb-6"}>
								<span>{t("quizzes:review")}</span>: {currentQuiz.title}
							</h1>
						</div>
						<button
							onClick={() => navigate(`/quizzes/${currentQuiz.id}/ranking`)}
							className={"white-button"}
						>
							{t("review_ranking_btn")}
						</button>
					</div>
				</div>
			</section>
			<section className={"g-section-padding z-10"}>
				<div className="questions-container hero-title">
					{currentQuiz.questions ? (
						currentQuiz.questions.map((elem, id) => (
							<ReviewItem answer={currentQuiz.answers[id]} question={elem} key={id} />
						))
					) : (
						<Loading />
					)}
				</div>
			</section>
			<section className="top"></section>
		</div>
	);
};

export default connect(mapStateToProps, null)(QuizReview);
