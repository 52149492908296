import React, { useEffect } from "react";
import "./style.scss";
import GenerateQuizBlock from "../../components/home/generate_quiz_block/GenerateQuizBlock";
import { useTranslation } from "react-i18next";
import KPIBlock from "../../components/home/kpi_block/KPIBlock";
import ProcessBlock from "../../components/home/process_block/process_block";
import AdvantageBlock from "../../components/home/advantage_block/advantage_block";
import CtaBlock from "../../components/general/cta_block/cta_block";
import PartnerBlock from "../../components/home/partner_block/partner_block";

const Home = () => {
	const { t, i18n } = useTranslation("home", "main");
	useEffect(() => {
		document.getElementById("navbar").classList.add("reverse");

		return () => {
			document.getElementById("navbar").classList.remove("reverse");
		};
	}, []);

	return (
		<div id="home">
			<section className="home-black-section">
				<div className="container">
					<h1
						className={"hero-title"}
						dangerouslySetInnerHTML={{
							__html: t("home_title", { interpolation: { escapeValue: false } }),
						}}
					></h1>
					<p>{t("home_description")}</p>
					<button
						className={"main-button"}
						onClick={() => window.scrollTo({ top: 350, left: 0, behavior: "smooth" })}
						size="large"
						variant="outlined"
					>
						{t("main:get_started_now")}
					</button>
				</div>
			</section>
			<section className={"g-section-padding"}>
				<GenerateQuizBlock />
			</section>
			<section className={"g-section-padding"}>
				<KPIBlock />
			</section>
			<section className={"g-section-padding"}>
				<ProcessBlock />
			</section>
			<section className={"g-section-padding bg-black text-white"}>
				<AdvantageBlock />
			</section>
			<CtaBlock />
			<section className={"g-section-padding"}>
				<PartnerBlock />
			</section>
		</div>
	);
};

export default Home;
