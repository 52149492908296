import React, { useState } from "react";
import "./style.scss";

function ProgressBar({ maxQuestion = 5, currentQuestion }) {
	return (
		<div className="progress-container">
			<div
				className="progress-bar"
				style={{ width: `${(currentQuestion / maxQuestion) * 100}%` }}
			></div>
		</div>
	);
}

export default ProgressBar;
