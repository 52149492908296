import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useParams } from "react-router-dom";
import axios from "../../../services/axios";
import Loading from "../../../components/main/loading/Loading";
import AuthContext from "../../../contexts/AuthContext";

const SpecificUser = () => {
	const { user } = useContext(AuthContext);
	let { id, pseudo } = useParams();

	const [selectedUser, setSelectedUser] = useState(!id || !pseudo ? user : null);

	if (!id || !pseudo) {
		id = user.id;
		pseudo = user.first_name;
	}

	const load_user = async () => {
		const ret = await axios
			.get(`/users/${id}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			const quizzes = await axios
				.get(`/users/${id}/quizzes`)
				.then((e) => e.data)
				.catch((e) => null);
			if (quizzes) {
				setSelectedUser({ ...ret, quizzes: quizzes });
			}
		}
	};

	useEffect(() => {
		if (!selectedUser) load_user();
	}, []);

	if (!selectedUser) {
		return <Loading />;
	}

	return (
		<div id="specific-user">
			<h1>
				{selectedUser.first_name} {selectedUser.last_name}
			</h1>
			<div className="quiz-container">
				{selectedUser.quizzes?.map((elem, index) => (
					<div key={index} className="quiz-item">
						<h2>{elem.title}</h2>
					</div>
				))}
			</div>
		</div>
	);
};

export default SpecificUser;
