import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../general/section_title/section_title";
import whiteImage from "../../../assets/images/White.png";

function AdvantageItem({ imageUrl = whiteImage, title, desc, lastElement = false }) {
	const { t } = useTranslation("home");

	return (
		<div
			className={
				"advantage-item w-full flex h-fit " +
				(lastElement
					? "last-advantage flex-col md:flex-row md:p-10 md:col-span-2 md:justify-center md:items-center"
					: "flex-col")
			}
		>
			<div
				className={"advantage-img-container " + (lastElement ? "md:w-2/5 h-1/2" : "h-1/2 ")}
			>
				<img
					className={"advantage-img " + (lastElement ? "horizontal" : "inclined")}
					alt={title}
					src={imageUrl}
				/>
			</div>
			<div className={"advantage-content p-6 " + (lastElement ? "md:w-2/3 md:ml-20" : "")}>
				<h3
					className={"two-lines mb-2"}
					dangerouslySetInnerHTML={{ __html: t(title) }}
				></h3>
				<p className={"two-lines mb-2"}>{t(desc)}</p>
			</div>
		</div>
	);
}

const AdvantageBlock = () => {
	const { t, i18n } = useTranslation("home", "main");

	const advantageList = [
		{
			image: whiteImage,
			title: "home_adv_1_title",
			desc: "home_adv_1_description",
		},
		{
			image: whiteImage,
			title: "home_adv_2_title",
			desc: "home_adv_2_description",
		},
		{
			image: whiteImage,
			title: "home_adv_3_title",
			desc: "home_adv_3_description",
		},
	];

	return (
		<div className={"advantage-block w-full flex-col h-fit pt-20 pb-32"}>
			<SectionTitle
				position={"middle"}
				subtitle={t("process_section_subtitle")}
				title={t("process_section_title")}
				description={t("process_section_desc")}
			></SectionTitle>
			<div
				className={
					"advantage-content mt-10 w-full flex flex-col md:grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-10 md:gap-20 auto-cols-min auto-rows-auto"
				}
			>
				{advantageList.map((item, index) => (
					<AdvantageItem
						key={index}
						desc={item.desc}
						title={item.title}
						imageUrl={item.image}
						lastElement={index === advantageList.length - 1}
					/>
				))}
			</div>
		</div>
	);
};

export default AdvantageBlock;
