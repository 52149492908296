import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import "./style.scss";

function formatMilliseconds(milliseconds) {
	let hours = Math.floor(milliseconds / (1000 * 60 * 60));
	milliseconds = milliseconds % (1000 * 60 * 60);

	// Calculate the number of minutes
	let minutes = Math.floor(milliseconds / (1000 * 60));
	milliseconds = milliseconds % (1000 * 60);

	// Calculate the number of seconds
	let seconds = Math.floor(milliseconds / 1000);

	// Ajout de zéro devant les minutes si nécessaire
	let formattedMinutes = minutes.toString().padStart(2, "0");
	let formatedSeconds = seconds.toString().padStart(2, "0");

	// Création de la chaîne de sortie
	let output =
		hours + " heures, " + formattedMinutes + " minutes et " + formatedSeconds + " secondes";

	// Renvoi de la chaîne formatée
	return output;
}

const Timer = ({ time_left }) => {
	const now = Date.now();
	const until = now + time_left;

	const [timeLeft, setTimeLeft] = useState(time_left);

	useEffect(() => {
		const interval = setInterval(() => {
			const now = Date.now();
			setTimeLeft(until - now);
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return <span className="timer">{formatMilliseconds(timeLeft)}</span>;
};

const AnonymourRestricted = () => {
	const { user } = useContext(AuthContext);
	if (!user.anonymous_restricted) {
		return null;
	}
	return (
		<div id="anonoymous_restricted">
			<span>Vous avez atteint votre limite quotidienne sans compte</span>
			<span>Pour refaire des quiz, creez un compte ou attendez</span>
			<Timer time_left={user.anonymous_restricted} />
		</div>
	);
};

export default AnonymourRestricted;
