// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
  width: 100%;
  background: #E8E7DB;
  border-radius: 20px;
  height: 6px;
}

.progress-bar {
  width: 0;
  height: 100%;
  background-color: var(--color-primary);
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/quizzes/progress_bar/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,QAAA;EACA,YAAA;EACA,sCAAA;EACA,mBAAA;AACF","sourcesContent":[".progress-container {\n  width: 100%;\n  background: #E8E7DB;\n  border-radius: 20px;\n  height: 6px;\n}\n\n.progress-bar {\n  width: 0;\n  height: 100%;\n  background-color: var(--color-primary);\n  border-radius: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
