import React, { useState, useEffect } from "react";
import "./style.scss";

function AnswerItem({ text, number, state = "", full_width = false }) {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkMobile = () => {
			setIsMobile(window.innerWidth < 768);
		};
		checkMobile();
		window.addEventListener("resize", checkMobile);
		return () => {
			window.removeEventListener("resize", checkMobile);
		};
	}, []);

	return (
		<div
			className={`answer-item ${
				full_width ? "full-width" : ""
			} flex flex-row items-center justify-start gap-4 xl:gap-4  ${state} `}
		>
			<span
				className={`letter-block flex items-center justify-center flex items-center justify-center
				`}
			>
				{"ABCDEFGHIJ"[number]}
			</span>
			<p className={`answer-text ${isMobile ? "" : ""}`}>{text}</p>
		</div>
	);
}

export default AnswerItem;
