import axios from "axios";

export const get_data_list = async (model, language) => {
	const items = await axios
		.get(`${process.env.REACT_APP_CONTENT_URL}/api/c/${model}/?locale=${language}`)
		.then((e) => e.data)
		.catch((e) => {
			console.log(e);
			return [];
		});
	return items;
};

export const get_data_item = async (model, id, language) => {
	const item = await axios
		.get(`${process.env.REACT_APP_CONTENT_URL}/api/c/${model}/${id}/?locale=${language}`)
		.then((e) => e.data)
		.catch((e) => null);
	return item;
};
