// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-title .section-title-item:not(:last-child) {
  margin-bottom: 1dvh;
}
.section-title span {
  color: var(--color-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/general/section_title/style.scss"],"names":[],"mappings":"AAEE;EACE,mBAAA;AADJ;AAGE;EACE,2BAAA;AADJ","sourcesContent":[".section-title{\n\n  .section-title-item:not(:last-child){\n    margin-bottom: 1dvh;\n  }\n  span{\n    color: var(--color-primary);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
