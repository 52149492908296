import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AuthPresentation = () => {
	const { t, i18n } = useTranslation(["home", "main", "messages"]);
	return (
		<div
			className={
				'presentation-bloc bg-background_black w-full shapedBackground lg:w-1/2 text-white"'
			}
		>
			<div className={"text-white"}>
				<Link to="/">
					<span className="big-title">
						Quiz<span>Battle</span>
					</span>
				</Link>
			</div>
			<div className={"text-container flex flex-col gap-4"}>
				<h2
					className={"hero-title text-white"}
					dangerouslySetInnerHTML={{
						__html: t("home_title", { interpolation: { escapeValue: false } }),
					}}
				></h2>
				<p
					className={"text-white opacity-80 text-sm"}
					dangerouslySetInnerHTML={{
						__html: t("login_pre_desc", {
							interpolation: { escapeValue: false },
						}),
					}}
				></p>
			</div>
			<p
				className={"text-white opacity-60 text-right text-xs w-full"}
				dangerouslySetInnerHTML={{
					__html: t("sigma_copyright", { interpolation: { escapeValue: false } }),
				}}
			></p>
		</div>
	);
};

export default AuthPresentation;
