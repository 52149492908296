// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.75rem;
  gap: 1dvh;
  background: rgba(0, 0, 0, 0.04);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 1dvh;
}
.switcher .switch-option {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
  padding: 2dvh 8dvh;
  cursor: pointer;
  transition: ease-out 0.5s;
  border-radius: 3.75rem;
}
.switcher .switch-option:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transition: ease-out 0.5s;
}
.switcher .switch-option.active {
  background-color: #000;
  color: var(--color-white);
}

@media only screen and (max-width: 600px) {
  .switcher {
    align-self: flex-start;
  }
  .switcher .switch-option {
    font-size: 14px;
    padding: 2dvw 7dvw;
  }
}
.btn-slider div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.btn-slider .slider-arrow.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/features/faq_block/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;EACA,+BAAA;EACA,kCAAA;UAAA,0BAAA;EACA,aAAA;AACD;AACC;EACC,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,sBAAA;AACF;AACE;EACC,qCAAA;EACA,yBAAA;AACH;AAEE;EACC,sBAAA;EACA,yBAAA;AAAH;;AAKA;EACC;IACC,sBAAA;EAFA;EAGA;IACC,eAAA;IACA,kBAAA;EADD;AACF;AASC;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AAPF;AAWE;EACC,YAAA;EACA,eAAA;EACA,oBAAA;AATH","sourcesContent":[".switcher {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tborder-radius: 3.75rem;\n\tgap: 1dvh;\n\tbackground: rgba(0, 0, 0, 0.04);\n\tbackdrop-filter: blur(2px);\n\tpadding: 1dvh;\n\n\t.switch-option {\n\t\tfont-weight: 300;\n\t\tcolor: rgba(0, 0, 0, 0.8);\n\t\tpadding: 2dvh 8dvh;\n\t\tcursor: pointer;\n\t\ttransition: ease-out 0.5s;\n\t\tborder-radius: 3.75rem;\n\n\t\t&:hover {\n\t\t\tbackground-color: rgba(0, 0, 0, 0.05);\n\t\t\ttransition: ease-out 0.5s;\n\t\t}\n\n\t\t&.active {\n\t\t\tbackground-color: #000;\n\t\t\tcolor: var(--color-white);\n\t\t}\n\t}\n}\n\n@media only screen and (max-width: 600px) {\n\t.switcher {\n\t\talign-self: flex-start;\n\t\t.switch-option {\n\t\t\tfont-size: 14px;\n\t\t\tpadding: 2dvw 7dvw;\n\t\t}\n\t}\n}\n\n.faq-block {\n}\n\n.btn-slider {\n\tdiv {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tborder-radius: 30px;\n\t}\n\n\t.slider-arrow {\n\t\t&.disabled {\n\t\t\topacity: 0.5;\n\t\t\tcursor: default;\n\t\t\tpointer-events: none;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
