import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import "../login/style.scss";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../components/main/loading_button/LoadingButton";
import { App } from "antd";
import AuthPresentation from "../../../components/auth/auth_presentation/AuthPresentation";
import axios_auth from "../../../services/auth_axios";

const ForgotPassword = () => {
	const [loading, setLoading] = useState(false);
	const { notification } = App.useApp();
	let [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		email: "",
	});

	const { t } = useTranslation(["home", "main", "messages"]);

	const handleClick = async (e) => {
		e.preventDefault();
		setLoading(true);
		let error = "";
		const link = await axios_auth
			.post("/users/forgot-password", { email: formData.email })
			.then((e) => e.data)
			.catch((e) => {
				error = e.response.data.message;
				return null;
			});

		setLoading(false);
		if (!link) {
			if (error == "google") {
				notification.error({
					message: t("messages:error_forgot_google_message"),
					description: t("messages:error_forgot_google_description"),
					placement: "bottomLeft",
				});
			} else {
				notification.error({
					message: t("messages:error_forgot_email_message"),
					placement: "bottomLeft",
				});
			}
		} else {
			notification.success({
				message: t("messages:success_forgot_message"),
				description: t("messages:success_forgot_description"),
				placement: "bottomLeft",
			});
			navigate("/login");
		}
		setFormData({ email: "" });
	};
	// For mobile header color
	useEffect(() => {
		document.querySelector(".mobile-header").classList.add("on-light");
		return () => {
			document.querySelector(".mobile-header").classList.remove("on-light");
		};
	}, []);

	return (
		<section id="verify-email">
			<section className={"container for-log g-section-padding mb-20"}>
				<div className={"form-container flex flex-col gap-8 w-full lg:w-1/2"}>
					<div className={"text-container flex flex-col gap-2"}>
						<h2
							className={"section-title"}
							dangerouslySetInnerHTML={{
								__html: t("forgot_password", {
									interpolation: { escapeValue: false },
								}),
							}}
						></h2>
						<p
							className={"opacity-80 text-sm"}
							dangerouslySetInnerHTML={{
								__html: t("forgot_password_desc", {
									email: searchParams.get("email"),
									interpolation: { escapeValue: false },
								}),
							}}
						></p>
					</div>
					<form className={""}>
						<div className={"label-line w-full mb-2"}>
							<label id={"email"}>{t("login_email_text")}</label>
						</div>
						<input
							placeholder={t("login_email_placeholder_text")}
							value={formData.email}
							onChange={(e) => setFormData({ ...formData, email: e.target.value })}
							type="text"
						/>

						<LoadingButton button={true} loading={loading} onClick={handleClick}>
							{t("get_reset_link")}
						</LoadingButton>
					</form>
				</div>
				<AuthPresentation />
			</section>
		</section>
	);
};

export default ForgotPassword;
