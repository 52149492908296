import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const fetchQuizzes = createAsyncThunk("quizzes/fetchQuizzes", async (user_id) => {
	const ret = await axios.get(`/users/${user_id}/quizzes`);
	return ret.data;
});

const quizzesSlice = createSlice({
	name: "quizzes",
	initialState: {
		current_quiz: null,
		history: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchQuizzes.fulfilled, (state, { payload }) => {
			return { ...state, history: payload };
		});
	},
});

export const quizzesMethods = {
	fetchQuizzes,
};

export default quizzesSlice;
