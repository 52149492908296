import React, { useContext, useState, useEffect } from "react";
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { Menu, X } from "react-feather";
import { generate_profile_image } from "../../../helpers/image";
import MainIcon from "../../../assets/icones/main-icon.svg";

const NavbarLink = ({ url, label, closeFunct }) => {
	const location = useLocation();

	return (
		<li className={location.pathname === url ? "active" : ""} onClick={closeFunct}>
			<Link to={url}>
				<span>{label}</span>
			</Link>
		</li>
	);
};

const scrol_listener = () => {
	//const location = useLocation();
	const pageName = window.location.pathname.split("/").pop();

	let lastScrollTop = 0;
	const header = document.getElementById("navbar");
	window.addEventListener(
		"scroll",
		() => {
			var st = window.scrollY;
			if (st > lastScrollTop && window.scrollY > 30) {
				header.classList.add("nav-hidden");
			} else {
				if (window.scrollY > 60) {
					header.classList.add("sticky");
					document.querySelector(".mobile-header").classList.add("on-scroll");
					if (header.classList.contains("nav-hidden")) {
						header.classList.remove("nav-hidden");
					}
				} else if (window.scrollY <= 60) {
					document.querySelector(".mobile-header").classList.remove("on-scroll");
					if (header.classList.contains("sticky")) header.classList.remove("sticky");
					if (header.classList.contains("nav-hidden")) {
						header.classList.remove("nav-hidden");
					}
				}
			}
			lastScrollTop = st <= 0 ? 0 : st;
		},
		false
	);
};

const Navbar = () => {
	const { is_login, user } = useContext(AuthContext);
	const { t, i18n } = useTranslation("main");
	const location = useLocation();
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", scrol_listener);

		return () => {
			window.removeEventListener("scroll", scrol_listener);
		};
	}, []);

	useEffect(() => {
		if (isMenuOpen) {
			document.body.style.overflowY = "hidden";
			setTimeout(() => {
				document.querySelectorAll(".menu-open li").forEach((li, index) => {
					setTimeout(() => {
						li.classList.add("visible");
					}, index * 200);
				});
			}, 500); // après l'animation du conteneur menu-open
		}
		return () => {
			document.querySelectorAll(".menu-open li").forEach((li) => {
				li.classList.remove("visible");
			});
			document.body.style.overflowY = "unset";
		};
	}, [isMenuOpen]);

	const closeMenu = () => {
		setIsMenuOpen(false);
	};
	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<header className="section" id="navbar">
			<nav className="container">
				<div className={"web-menu w-full flex flex-row justify-between"}>
					<ul>
						<NavbarLink url="/home" label={t("navbar_home")} />
						<NavbarLink url="/features" label={t("navbar_features")} />
						<NavbarLink url="/pricing" label={t("navbar_pricing")} />
					</ul>
					<div>
						<Link to="/">
							<div className="icon-title-container">
								<img src={MainIcon} alt="QuizBattle Icon" />
								<span className="big-title z-10 ">
									Quiz<span>Battle</span>
								</span>
							</div>
						</Link>
					</div>
					<ul>
						{is_login() ? (
							<>
								<li className="language-selector">
									<span
										onClick={() => i18n.changeLanguage("en")}
										className={i18n.language.startsWith("en") ? "active" : ""}
									>
										En
									</span>{" "}
									|{" "}
									<span
										onClick={() => i18n.changeLanguage("fr")}
										className={i18n.language.startsWith("fr") ? "active" : ""}
									>
										Fr
									</span>
								</li>
								<NavbarLink url="/history" label={t("navbar_history")} />
								<li className={location.pathname === "/profile" ? "active" : ""}>
									<Link className="profile-link" to="/profile">
										<img src={generate_profile_image(user)} />
										<span>{user.first_name}</span>
									</Link>
								</li>
							</>
						) : user.token != null ? (
							<>
								<li className="language-selector">
									<span
										onClick={() => i18n.changeLanguage("en")}
										className={i18n.language.startsWith("en") ? "active" : ""}
									>
										En
									</span>{" "}
									|{" "}
									<span
										onClick={() => i18n.changeLanguage("fr")}
										className={i18n.language.startsWith("fr") ? "active" : ""}
									>
										Fr
									</span>
								</li>
								<Link to={"/login"}>
									<button className={"white-button"}>
										<span>{t("navbar_login")}</span>
									</button>
								</Link>
							</>
						) : null}
					</ul>
				</div>

				<div className="mobile-header">
					{isMenuOpen ? (
						<div className="language-selector mobile">
							<span
								onClick={() => i18n.changeLanguage("en")}
								className={i18n.language.startsWith("en") ? "active" : ""}
							>
								En
							</span>{" "}
							|{" "}
							<span
								onClick={() => i18n.changeLanguage("fr")}
								className={i18n.language.startsWith("fr") ? "active" : ""}
							>
								Fr
							</span>
						</div>
					) : (
						<Link to="/">
							<span className="big-title">
								Quiz<span>Battle</span>
							</span>
						</Link>
					)}
					<button
						onClick={toggleMenu}
						className={"z-10 menu-btn"}
						style={isMenuOpen ? { borderColor: "rgba(0,0,0,0.1)" } : {}}
					>
						{isMenuOpen ? <X color={"black"} size={24} /> : <Menu size={24} />}
					</button>
				</div>
				<div className={`menu-open ${isMenuOpen ? "open" : ""}`}>
					{/* Menu pour mobile */}
					<div className={"link-wrapper"}>
						<NavbarLink url="/home" label={"Home"} closeFunct={closeMenu} />
						<NavbarLink url="/features" label={"Features"} closeFunct={closeMenu} />
						{is_login() ? (
							<NavbarLink
								url="/specifications"
								label={"Quiz"}
								closeFunct={closeMenu}
							/>
						) : null}
						<NavbarLink url="/pricing" label={"Pricing"} closeFunct={closeMenu} />
						{is_login() ? (
							<>
								<NavbarLink
									url="/history"
									label={t("navbar_history")}
									closeFunct={closeMenu}
								/>
								<NavbarLink
									url="/profile"
									label={t("navbar_profile")}
									closeFunct={closeMenu}
								/>
							</>
						) : (
							<Link to={"/login"} onClick={closeMenu}>
								<button className={"white-button"}>
									<span>{t("navbar_login")}</span>
								</button>
							</Link>
						)}
					</div>
				</div>
			</nav>
		</header>
	);
};

export default Navbar;
