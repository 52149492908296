// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer-item-container .answer-item {
  transition: 0.3s;
}
.answer-item-container .answer-item:hover {
  border-color: var(--color-);
}
.answer-item-container.loading.unselected .answer-item {
  opacity: 0.4;
}

.answer-item {
  border-radius: 30px;
  border: 1px solid rgba(213, 213, 213, 0.8);
  padding: 10px 10px;
  width: calc(50% - 20px);
}
.answer-item.full-width {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .answer-item {
    width: 100%;
  }
}
.answer-item.failed {
  color: var(--color-error);
  border: 1px solid var(--color-error);
}
.answer-item.failed .letter-block {
  border: 1px solid var(--color-error);
}
.answer-item.failed p {
  color: var(--color-error);
}
.answer-item.correct {
  color: var(--color-white);
  border: 1px solid #00a94b;
  background: #00a94b;
  box-shadow: 0px 4px 20px 0px rgba(0, 169, 75, 0.25);
}
.answer-item.correct .letter-block {
  background: rgba(255, 255, 255, 0.5);
  stroke-width: 1px;
  border: none;
}
.answer-item.correct p {
  color: var(--color-white);
}
.answer-item .letter-block {
  height: 40px;
  width: 40px;
  border-radius: 30px;
  border: 1px solid rgba(213, 213, 213, 0.8);
}
.answer-item .answer-text {
  display: flex;
  width: calc(100% - 40px);
}`, "",{"version":3,"sources":["webpack://./src/components/quizzes/answer_item/style.scss"],"names":[],"mappings":"AACC;EACC,gBAAA;AAAF;AAEE;EACC,2BAAA;AAAH;AAMG;EACC,YAAA;AAJJ;;AAUA;EACC,mBAAA;EACA,0CAAA;EACA,kBAAA;EACA,uBAAA;AAPD;AASC;EACC,WAAA;AAPF;AAUC;EAVD;IAWE,WAAA;EAPA;AACF;AASC;EACC,yBAAA;EACA,oCAAA;AAPF;AASE;EACC,oCAAA;AAPH;AAUE;EACC,yBAAA;AARH;AAWC;EACC,yBAAA;EACA,yBAAA;EACA,mBAAA;EACA,mDAAA;AATF;AAWE;EACC,oCAAA;EACA,iBAAA;EACA,YAAA;AATH;AAYE;EACC,yBAAA;AAVH;AAcC;EACC,YAAA;EACA,WAAA;EACA,mBAAA;EACA,0CAAA;AAZF;AAeC;EACC,aAAA;EACA,wBAAA;AAbF","sourcesContent":[".answer-item-container {\n\t.answer-item {\n\t\ttransition: 0.3s;\n\n\t\t&:hover {\n\t\t\tborder-color: var(--color-);\n\t\t}\n\t}\n\n\t&.loading {\n\t\t&.unselected {\n\t\t\t.answer-item {\n\t\t\t\topacity: 0.4;\n\t\t\t}\n\t\t}\n\t}\n}\n\n.answer-item {\n\tborder-radius: 30px;\n\tborder: 1px solid rgba(213, 213, 213, 0.8);\n\tpadding: 10px 10px;\n\twidth: calc(50% - 20px);\n\n\t&.full-width {\n\t\twidth: 100%;\n\t}\n\n\t@media only screen and (max-width: 600px) {\n\t\twidth: 100%;\n\t}\n\n\t&.failed {\n\t\tcolor: var(--color-error);\n\t\tborder: 1px solid var(--color-error);\n\n\t\t.letter-block {\n\t\t\tborder: 1px solid var(--color-error);\n\t\t}\n\n\t\tp {\n\t\t\tcolor: var(--color-error);\n\t\t}\n\t}\n\t&.correct {\n\t\tcolor: var(--color-white);\n\t\tborder: 1px solid #00a94b;\n\t\tbackground: #00a94b;\n\t\tbox-shadow: 0px 4px 20px 0px rgba(0, 169, 75, 0.25);\n\n\t\t.letter-block {\n\t\t\tbackground: rgba(255, 255, 255, 0.5);\n\t\t\tstroke-width: 1px;\n\t\t\tborder: none;\n\t\t}\n\n\t\tp {\n\t\t\tcolor: var(--color-white);\n\t\t}\n\t}\n\n\t.letter-block {\n\t\theight: 40px;\n\t\twidth: 40px;\n\t\tborder-radius: 30px;\n\t\tborder: 1px solid rgba(213, 213, 213, 0.8);\n\t}\n\n\t.answer-text {\n\t\tdisplay: flex;\n\t\twidth: calc(100% - 40px);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
